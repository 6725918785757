import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseApiService} from './base-api.service';
import {AuthApiService} from "./auth-api.service";
import {Survey, SurveyResponse, SurveyResponseStatus, User} from "../model/common";
import {map} from "rxjs/operators";
import {ConfigService} from "./config.service";

@Injectable()
export class SurveyService extends BaseApiService {

  constructor(
    public http: HttpClient,
    private auth: AuthApiService,
    public configService: ConfigService,
  ) {
    super();
  }

  /*
  0 - invited
  1 - in progress
  2 - complete
   */
  public static getSurveyResponseStatus(surveyResponse: SurveyResponse): SurveyResponseStatus {
    if (surveyResponse.exclude_from_results) {
      return {code: 3, label: 'Excluded'};
    }
    if (surveyResponse.submitted) {
      return {code: 2, label: 'Complete'};
    }

    if (surveyResponse.progress) {
      return {code: 1, label: 'In Progress'};
    } else {
      return {code: 0, label: 'Invited'};
    }
  }

  static getSurveyProgress(surveyResponse: SurveyResponse) {
    if (surveyResponse.progress) { return surveyResponse.progress; }
    if (!surveyResponse.response) { return 0; }
    return surveyResponse.response['progress'] || '0';
  }


  static isProgressBarEligible(surveyResponse: SurveyResponse): boolean {
    if (!surveyResponse.response) {
      return false;
    }
    return surveyResponse.response.progress > 20;
  }

  get edPath(): String {
    return this.auth.user.ed.path;
  }

  public get url(): string {
    return this.configService.apiEndpoint + `/api/ed/${this.edPath}/surveys`;
  }

  all() {
    return this.get('/');
  }

  getSurvey(id) {
    return this.get(`/${id}`);
  }

  getSurveyResults(id, payload) {
    return this.post(`/${id}/results`, payload);
  }

  update(id, params) {
    return this.post(`/${id}`, params);
  }

  create(params) {
    return this.post('/', params);
  }

  updateDefinition(surveyID, definition) {
    return this.post(`/${surveyID}/definition`, definition);
  }

  updateInfo(surveyID, survey) {
    return this.post(`/${surveyID}/info`, survey);
  }

  closeSurvey(surveyID, survey) {
    return this.post(`/${surveyID}/close`, survey);
  }

  getUsers(surveyID) {
    return this.get(`/${surveyID}/users`);
  }

  inviteUsers(surveyID, users: User[]) {
    return this.post(`/${surveyID}/invite-users`, users);
  }

  resendInvitations(surveyID, users: User[]) {
    return this.post(`/${surveyID}/resend-invitations`, users);
  }

  pause(surveyID) {
    return this.post(`/${surveyID}/pause`, null);
  }

  unpause(surveyID) {
    return this.post(`/${surveyID}/unpause`, null);
  }

  cancel(surveyID) {
    return this.post(`/${surveyID}/cancel`, null);
  }

  getResponses(surveyID) {
    return this.get(`/${surveyID}/responses`).pipe(map((r: SurveyResponse[]) => {
      return r.map(row => {
        row['status'] = SurveyService.getSurveyResponseStatus(<SurveyResponse>row);
        return row;
      });
    }));
  }

  getResponse(surveyID, responseID) {
    return this.get(`/${surveyID}/response/${responseID}`);
  }

  delSurvey(surveyID) {
    return this.delete(`/${surveyID}`);
  }

  takeSurvey(surveyID) {
    return this.get(`/${surveyID}/take`);
  }

  saveState(surveyResponse: SurveyResponse) {
    return this.post(`/${surveyResponse.survey_id}/save-state`, surveyResponse);
  }

  submit(surveyResponse: SurveyResponse) {
    return this.post(`/${surveyResponse.survey_id}/submit`, surveyResponse);
  }

  getSurveyPrintPath(survey: Survey) {
    return `/${this.edPath}/results/${survey.id}/print`;
  }

  requestSurveyInvite(surveyID) {
    return this.post(`/${surveyID}/request-invite`, null);
  }

  getResults() {
    return this.get('/results');
  }

  setResponseExcludedFlag(surveyResponse: SurveyResponse, flag) {
    return this.post(`/${surveyResponse.survey_id}/set-excluded`, {survey_response_id: surveyResponse.id, exclude_from_results: flag});
  }
}
