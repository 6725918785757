import {Component, Input, OnInit} from '@angular/core';
import {SurveyResultsQuestion} from "../../../../../../model/common";
import * as math from "mathjs";

@Component({
  selector: 'app-block-tripetto-number-result',
  templateUrl: './block-tripetto-number-result.component.html',
  styleUrls: ['./block-tripetto-number-result.component.scss']
})
export class BlockTripettoNumberResultComponent implements OnInit {

  @Input("question") question: SurveyResultsQuestion;

  options = [];

  constructor() {
  }

  public static calcStats(data, includeNCount = false) {

    let values = [];

    Object.keys(data).forEach(key => {
      let v = parseInt(data[key], 10);
      let k = Math.round(parseFloat(key) * 100) / 100;
      for (let i = 0; i < v; i++) { values.push(k); }
    });

    values = values.sort((a, b) => a - b);

    let count = values.length;
    let results = [];

    if (includeNCount) {
      results.push({label: 'N', value: count});
    }

    [10, 25, 50, 75, 90].forEach(function (percentile) {

      if (count == 1) {
        results.push({label: percentile + 'th percentile', value: values[0].toLocaleString('en-US') });
        return;
      }

      let index = (percentile / 100) * count;
      index--;

      let value = null;

      if (Number.isInteger(index)) {
        if (index < 0 ) {
          index = 0;
        }
        let nextVal = values[index + 1] || values[index];
        value = math.mean(values[index], nextVal);
      } else {
        value = values[Math.ceil(index)];
      }

      results.push({label: percentile + 'th percentile', value: value.toLocaleString('en-US')});
    });


    results.push({
      label: 'Mean',
      value: math.round(math.mean(values)).toLocaleString('en-US')
    });

    results.push({
      label: 'Median',
      value: math.round(math.median(values)).toLocaleString('en-US')
    });

    results.push({
      label: 'Standard deviation',
      value: math.round(math.std(values)).toLocaleString('en-US')
    });

    return results;
  }

  ngOnInit() {
    if (this.question.results['answers']) {
      let optionNames = Object.keys(this.question.results['answers']);
      this.options = optionNames.map((e) => {
        return {'name': e};
      });
      this.question.results['stats'] = BlockTripettoNumberResultComponent.calcStats(this.question.results['answers']);
    }

  }

}
