import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';

import {BaseApiService} from './base-api.service';

import {BehaviorSubject, Observable, of} from 'rxjs';

import {User} from '../model/common';
import {ConfigService} from "./config.service";
import * as Sentry from "@sentry/browser";

@Injectable()
export class AuthApiService extends BaseApiService {

  onUserChange: BehaviorSubject<any> = new BehaviorSubject({});

  private _user: User;

  public appResponse: any;

  get user(): User {
    return this._user;
  }

  set user(user) {
    Sentry.configureScope((scope) => {
      scope.setUser(user ? {
        "id": user.id.toString(),
        "email": user.email
      } : null);
    });

    this._user = user;
    this.onUserChange.next(this.user);
    this.cacheSet('auth.me', user, 1600);
  }

  public get url(): string {
    return this.configService.apiEndpoint + '/api/auth';
  }

  constructor(public http: HttpClient, private router: Router, private configService: ConfigService) {
    super();

    if (this.cacheGet('auth.me')) {
      this.user = this.cacheGet('auth.me');
    }

  }

  checkAuth(): Observable<boolean> {
    if (this.user) {
      return of(true);
    }

    return this.get('/me').pipe(
      map((r) => {
        if (r['user']) {
          this.processAuthResponse(r);
          return true;
        }
      }, (e) => {
        return false;
      })
    );
  }

  sendPasswordResetEmail(email: string): Observable<any> {
    const body = new FormData();
    body.append('email', email.toString());
    return this.post('/send-password-reset-email', body).pipe(
      map((r) => {
          return r;
        }
      )
    );
  }

  passwordReset(email: string = '', password: string = '', cpassword: string = '', token: string = '') {
    const body = new FormData();
    body.append('email', email.toString());
    body.append('password', password.toString());
    body.append('password_confirmation', cpassword.toString());
    body.append('token', token.toString());
    return this.post('/password/reset', body);
  }


  private processAuthResponse(response) {
    if (response['user']) {
      this.user = response.user;
    } else {
      this.appResponse = response;
      // console.log(this.appResponse);
    }
  }


  get EDRoles() {
    return [
      {value: 20, viewValue: 'Account Owner'},
      {value: 30, viewValue: 'Account Member'}
    ];
  }

  get userUserRoles() {
    return [
      // {value: 40, viewValue: 'Business Owner'},
      {value: 50, viewValue: 'User'},
    ];
  }

  get userRoles() {

    let roles = [{value: 10, viewValue: 'Admin'}];

    roles.push(...this.EDRoles);
    roles.push(...this.userUserRoles);

    if (!this.isSuperAdmin()) {
      roles.unshift();
    }

    return roles;
  }

  login(email: String, password: String): Observable<boolean> {
    const body = new FormData();
    body.append('email', email.toString());
    body.append('password', password.toString());
    return this.post('/login', body)
      .pipe(
        map((response) => {
          if (response['user']) {
            this.processAuthResponse(response);
            return true;
          } else {
            this.processAuthResponse(response);
            return false;
          }
        }),
        catchError(err => {
          // console.log(err.error.errors);
          throw err;
        })
      );
  }


  loginWithToken(token: String): Observable<boolean> {
    const body = new FormData();
    body.append('token', token.toString());
    return this.post('/login-token', body)
      .pipe(
        map((response) => {
          if (response['user']) {
            this.processAuthResponse(response);
            return true;
          } else {
            this.processAuthResponse(response);
            return false;
          }
        }),
        catchError(err => {
          // console.log(err.error.errors);
          throw err;
        })
      );
  }

  logout() {
    this.user = null;
    return this.post('/logout', null);
  }

  isSuperAdmin() {
    return this.user && this.user.role_id === 10;
  }

  isEDMember() {
    return this.user && this.user.ed && this.user.role_id <= 30;
  }

  isUser() {
    return this.user && this.user.ed && this.user.role_id >= 40;
  }

  isEDOwner() {
    return this.user && this.user.ed && this.user.role_id <= 20;
  }

  getED() {
    return this.user && this.user.ed ? this.user.ed.path : null;
  }

  getEDPath() {
    return this.getED() ? '/' + this.getED() : '';
  }

  getRootPath() {
    return this.getED() ? this.getEDPath() : '/admin';
  }

}
