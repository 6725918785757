import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Occupation, Survey, SurveyResultsSection} from "../../../../../../model/common";
import {IndustriesService} from "../../../../../../core/industries.service";
import {AuthApiService} from "../../../../../../core/auth-api.service";
import {MessageComponent} from "../../../../../../component/message";
import {BlockTripettoNumberResultComponent} from "../../result-blocks/block-tripetto-number-result/block-tripetto-number-result.component";

@Component({
  selector: 'app-survey-results-occupation-result',
  templateUrl: './survey-results-occupation-result.component.html',
  styleUrls: ['./survey-results-occupation-result.component.scss']
})
export class SurveyResultsOccupationResultComponent implements OnInit {

  @ViewChild('message') message: MessageComponent;

  @Input('survey') survey: Survey;
  @Input('occupation') occupation: Occupation;
  @Input('surveyResultsSection') surveyResultsSection: SurveyResultsSection;
  @Input('showResultsBelowSafeHarbor') showResultsBelowSafeHarbor: boolean;

  occupationQuestions = [];

  regularQuestions = [
    'job_description_match',
    'job_title',
    'incumbents_count',
    'flsa_status',
    'hourly_rate_or_annual_salary',
  ];

  groupNumberQuestions = [
    'min_range_base_salary_fulltime',
    'max_range_base_salary_fulltime',
    'max_hire_base_salary_fulltime',
    'min_actual_base_salary_fulltime',
    'max_actual_base_salary_fulltime',
    'base_salary_fulltime',
    'other_cash_compensation',
    'min_range_rate',
    'max_range_rate',
    'min_actual_rate',
    'base_rate',
    'max_actual_rate',
    'step_progression_num_steps',
    'other_cash_compensation_hourly',
  ];

  groupQuestions = [];

  constructor(
    private industriesService: IndustriesService,
    private auth: AuthApiService,
  ) { }

  ngOnInit() {
    if (this.auth.isUser()) {
      this.showResultsBelowSafeHarbor = false;
    }

    console.log(this.occupation, "debug test")

    this.initOccupationDescription(this.occupation);
    this.occupationQuestions = JSON.parse(JSON.stringify(this.surveyResultsSection.questions));

    this.occupationQuestions.forEach((val) => {
      if (this.occupation['questions'].hasOwnProperty(val['alias'])) {
        val.results = this.occupation['questions'][val['alias']]['results'];

        if (this.groupNumberQuestions.includes(val['alias'])) {
          val['results']['stats'] = BlockTripettoNumberResultComponent.calcStats(val['results']['answers'], true);
          this.groupQuestions.push(val);
        }
      }
    });
  }


  initOccupationDescription(occupation: Occupation) {
    this.industriesService.getOccupation(occupation.id).subscribe(r => {
      this.occupation.description = r['description'];
    }, e => {
      this.message.renderApiError(e);
    });
  }

  areAllQuestionsLowAccuracy() {
    for (const key in this.occupation['questions']) {
      if (this.occupation['questions'].hasOwnProperty(key)) {
        const question = this.occupation['questions'][key];
        if (question.results['answered_count'] >= this.survey.safe_harbor_count) {
          return false;
        }
      }
    }
    return true;
  }

  isLowAccuracyQuestion(question) {
    if (question.results['answered_count'] < this.survey.safe_harbor_count) {
      return true;
    } else {
      return false;
    }
  }

  failToSafeHarborQuestion(question): boolean {
    if (this.showResultsBelowSafeHarbor) {
      return false;
    }

    return this.isLowAccuracyQuestion(question);
  }

  failToSafeHarborSection(): boolean {
    if (this.showResultsBelowSafeHarbor) {
      return false;
    }

    return this.areAllQuestionsLowAccuracy();
  }

}
