import {NgModule} from '@angular/core';
import {CommonModule, PercentPipe} from '@angular/common';
import {RouterModule} from '@angular/router';

import {EDRoutingModule} from './ed-routing.module';
import {DashboardComponent} from './dashboard/dashboard.component';
import {StatisticsComponent} from './statistics/statistics.component';
import {SharedModule} from "../../shared/shared.module";
import {LoadingModule} from "../../component/loading";
import {EDComponent} from "./ed.component";
import {UsersComponent} from './users/users.component';
import {SurveysComponent} from './surveys/surveys.component';
import {ComponentsModule} from "../../components/components.module";
import {DialogModule} from "../../component/dialog";
import {StartSurveyComponent} from './surveys/start-survey/start-survey.component';
import {ListSurveysComponent} from './surveys/list-surveys/list-surveys.component';
import {StepSelectSurveyTemplateComponent} from './surveys/steps/step-select-survey-template/step-select-survey-template.component';
import {StepIndustryAndOccupationListComponent} from './surveys/steps/step-industry-and-occupation-list/step-industry-and-occupation-list.component';
import {StepPreviewSurveyQuestionsComponent} from './surveys/steps/step-preview-survey-questions/step-preview-survey-questions.component';
import {StepSurveyDetailsFormComponent} from './surveys/steps/step-survey-details-form/step-survey-details-form.component';
import {StepInviteUsersComponent} from './surveys/steps/step-invite-users/step-invite-users.component';
import {InviteUsersDialogComponent} from './surveys/steps/step-invite-users/invite-users-dialog/invite-users-dialog.component';
import {NewUserDialogComponent} from './surveys/steps/step-invite-users/new-user-dialog/new-user-dialog.component';
import {EditSurveyDetailsComponent} from './surveys/modals/edit-survey-details/edit-survey-details.component';
import {InviteSurveyUsersModalComponent} from './surveys/modals/invite-survey-users-modal/invite-survey-users-modal.component';
import {TakeSurveyComponent} from './survey/take-survey/take-survey.component';
import {UserDashboardComponent} from './dashboard/user-dashboard/user-dashboard.component';
import {EDDashboardComponent} from './dashboard/eddashboard/eddashboard.component';
import {DashboardSurveyComponent} from './dashboard/eddashboard/dashboard-survey/dashboard-survey.component';
import {UserDashboardSurveyComponent} from './dashboard/user-dashboard/user-dashboard-survey/user-dashboard-survey.component';
import {SurveyResultsComponent} from "./survey/survey-results/survey-results.component";
import {SurveyResultsSectionComponent} from './survey/survey-results/survey-results-section/survey-results-section.component';
import {SurveyResultsQuestionComponent} from './survey/survey-results/survey-results-question/survey-results-question.component';
import {BlockTripettoDropdownResultComponent} from './survey/survey-results/result-blocks/block-tripetto-dropdown-result/block-tripetto-dropdown-result.component';
import {BlockTripettoRadiobuttonsResultComponent} from './survey/survey-results/result-blocks/block-tripetto-radiobuttons-result/block-tripetto-radiobuttons-result.component';
import {BlockMatrixCheckboxesResultComponent} from './survey/survey-results/result-blocks/block-matrix-checkboxes-result/block-matrix-checkboxes-result.component';
import {BlockMatrixRadiobuttonsResultComponent} from './survey/survey-results/result-blocks/block-matrix-radiobuttons-result/block-matrix-radiobuttons-result.component';
import {BlockMatrixDropdownsResultComponent} from './survey/survey-results/result-blocks/block-matrix-dropdowns-result/block-matrix-dropdowns-result.component';
import {BlockCheckboxesResultComponent} from './survey/survey-results/result-blocks/block-checkboxes-result/block-checkboxes-result.component';
import {BlockCheckboxResultComponent} from './survey/survey-results/result-blocks/block-checkbox-result/block-checkbox-result.component';
import {ChartsModule} from 'ng2-charts';
import {SurveyResultsTableOfContentsComponent} from './survey/survey-results/survey-results-table-of-contents/survey-results-table-of-contents.component';
import {ViewSurveyComponent} from './survey/view-survey/view-survey.component';
import {ResultsComponent} from './results/results.component';
import {SurveyResultsFiltersComponent} from './survey/survey-results/survey-results-filters/survey-results-filters.component';
import {SurveyResultsCompensationComponent} from './survey/survey-results/compensation/survey-results-compensation/survey-results-compensation.component';
import {SurveyPrintResultsCompensationComponent} from './survey/survey-results/survey-print-results-compensation/survey-print-results-compensation.component';
import {SurveyResponsePrintComponent} from './survey/survey-results/survey-response-print/survey-response-print.component';

import {SurveyResultsOccupationResultComponent} from './survey/survey-results/compensation/survey-results-occupation-result/survey-results-occupation-result.component';
import {NgxChartsModule} from "@swimlane/ngx-charts";
import {SimpleBlockResultComponent} from './survey/survey-results/result-blocks/simple-block-result/simple-block-result.component';
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule} from "ngx-perfect-scrollbar";
import {TimeAgoPipe} from "time-ago-pipe";
import {SelectIndustriesAndOccupationsComponent} from './survey/take-survey/select-industries-and-occupations/select-industries-and-occupations.component';
import {SurveyPrintResultsComponent} from './survey/survey-results/survey-print-results/survey-print-results.component';
import {BlockTripettoNumberResultComponent} from './survey/survey-results/result-blocks/block-tripetto-number-result/block-tripetto-number-result.component';
import {BlockTripettoTextResultComponent} from './survey/survey-results/result-blocks/block-tripetto-text-result/block-tripetto-text-result.component';
import {CompileSurveyComponent} from './survey/compile-survey/compile-survey.component';

import {EdLogoComponent} from '../../pages/ed-logo/ed-logo.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    LoadingModule,
    EDRoutingModule,
    ComponentsModule,
    DialogModule,
    ChartsModule,
    NgxChartsModule,
    PerfectScrollbarModule,
    RouterModule,
  ],
  entryComponents: [
    InviteUsersDialogComponent,
    NewUserDialogComponent,
    EditSurveyDetailsComponent,
    InviteSurveyUsersModalComponent,
    TakeSurveyComponent,
    SurveyResultsComponent,
    ViewSurveyComponent,
    SelectIndustriesAndOccupationsComponent,
    CompileSurveyComponent
  ],
  declarations: [
    DashboardComponent,
    StatisticsComponent,
    EDComponent,
    UsersComponent,
    SurveysComponent,
    StartSurveyComponent,
    ListSurveysComponent,
    StepSelectSurveyTemplateComponent,
    StepIndustryAndOccupationListComponent,
    StepPreviewSurveyQuestionsComponent,
    StepSurveyDetailsFormComponent,
    StepInviteUsersComponent,
    InviteUsersDialogComponent,
    NewUserDialogComponent,
    EditSurveyDetailsComponent,
    InviteSurveyUsersModalComponent,
    TakeSurveyComponent,
    UserDashboardComponent,
    EDDashboardComponent,
    DashboardSurveyComponent,
    UserDashboardSurveyComponent,
    SurveyResultsComponent,
    SurveyResultsSectionComponent,
    SurveyResultsQuestionComponent,
    BlockTripettoDropdownResultComponent,
    BlockTripettoRadiobuttonsResultComponent,
    BlockMatrixCheckboxesResultComponent,
    BlockMatrixRadiobuttonsResultComponent,
    BlockMatrixDropdownsResultComponent,
    BlockCheckboxesResultComponent,
    BlockCheckboxResultComponent,
    SurveyResultsTableOfContentsComponent,
    ViewSurveyComponent,
    ResultsComponent,
    SurveyResultsFiltersComponent,
    SurveyResultsCompensationComponent,
    SurveyPrintResultsCompensationComponent,
    SurveyResponsePrintComponent,
    SurveyResultsOccupationResultComponent,
    SimpleBlockResultComponent,
    TimeAgoPipe,
    SelectIndustriesAndOccupationsComponent,
    SurveyPrintResultsComponent,
    BlockTripettoNumberResultComponent,
    BlockTripettoTextResultComponent,
    CompileSurveyComponent,
    EdLogoComponent,
  ],
  providers: [
    PercentPipe,
    TimeAgoPipe,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ],
  exports: [
    TakeSurveyComponent,
    EdLogoComponent,
  ]
})
export class EDModule {
}
