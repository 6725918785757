import {Component, Input, OnInit, AfterViewInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import {ActivatedRoute } from "@angular/router";
import {SurveyService} from "../../../../../core/survey.service";
import {AuthApiService} from "../../../../../core/auth-api.service";
import {ConfigService} from "../../../../../core/config.service";
import {ED, Survey, SurveyResults, SurveyResultsSection} from "../../../../../model/common";
import {catchError, finalize, map} from "rxjs/operators";
import {Observable, of} from "rxjs";
import {MessageComponent} from "../../../../../component/message";
import {UserSettingsService} from '../../../../../core/user.settings.service';

@Component({
  selector: 'app-survey-print-results',
  templateUrl: './survey-print-results.component.html',
  styleUrls: ['./survey-print-results.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SurveyPrintResultsComponent implements OnInit, AfterViewInit {

  @Input() survey: Survey;
  @Input() surveyResults: SurveyResults;

  @ViewChild('message') message: MessageComponent;

  EDPath = this.auth.getEDPath();
  ED: ED;
  isLoading = false;
  compensationSectionResults: SurveyResultsSection;
  private includeNotSubmittedResponses = false;
  private showResultsBelowSafeHarbor = false;
  private filterState = null;

  constructor(
    private route: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
    public surveyService: SurveyService,
    private auth: AuthApiService,
    private configService: ConfigService,
    private userSettingsService: UserSettingsService,
  ) { }

  ngOnInit() {
    this.ED = this.auth.user.ed;

    setTimeout(() => {
      this.configService.setSettings({printMode: true});
    }, 0);

    this.initFilters();
  }

  ngAfterViewInit() {
    this.includeNotSubmittedResponses = this.route.snapshot.queryParams['unsubmitted'] === 'true' ? true : false;
    this.showResultsBelowSafeHarbor = this.route.snapshot.queryParams['safe-harbor'] === 'true' ? true : false;
    const surveyId = this.route.snapshot.paramMap.get('survey_id');
    if (surveyId) {
      this.init(surveyId);
    }

    this.changeDetector.detach();
  }

  initFilters() {
    this.filterState = this.userSettingsService.getUserSetting('filters', 'Array');
  }


  public init(surveyID) {
    this.message.renderApiError();
    this.isLoading = true;

    const payload = {
      filters: this.filterState,
      includeNotSubmittedResponses: this.includeNotSubmittedResponses,
    };

    this.surveyService.getSurveyResults(surveyID, payload).pipe(finalize(() => {
      this.isLoading = false;
    })).pipe(map(
      r => {
        this.survey = <Survey>r['survey'];
        this.surveyResults = <SurveyResults>r['results'];
        this.compensationSectionResults = this.surveyResults.sections.filter((section) => section['is_compensation_survey']).pop();
        this.changeDetector.detectChanges();
      }), catchError((err, caught: Observable<any>) => {
        return of([]);
    })).subscribe();
  }

  sanitizeHref(key: string) {
    return key.replace(/[ /()-]/g, '_');
  }

  scrollToElement(elementRef): void {
    const element = document.getElementById(elementRef);
    if (element) {
      element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
    }
  }

}
