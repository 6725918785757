<h2 mat-dialog-title class="mb-2">
  Invite Users
  <button mat-icon-button type="button" color="primary" (click)="cancelClick()" ><mat-icon>close</mat-icon></button>
</h2>
<mat-dialog-content>
  <app-message messageType="alert" *ngIf="isEmptyUsers">
    Users will be invited when the survey is opened.
  </app-message>
  <table #usersTable class="users-table w-100" mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="first_name">
      <th mat-header-cell *matHeaderCellDef>First Name</th>
      <td mat-cell *matCellDef="let element"> {{element['first_name']}}</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="last_name">
      <th mat-header-cell *matHeaderCellDef>Last Name</th>
      <td mat-cell *matCellDef="let element"> {{element['last_name']}}</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>E-Mail</th>
      <td mat-cell *matCellDef="let element"> {{element['email']}}</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="business">
      <th mat-header-cell *matHeaderCellDef>Business Name</th>
      <td mat-cell *matCellDef="let element"> {{element.business.name}}</td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-stroked-button (click)="newUserClick()" class="mr-2"><mat-icon>person_add</mat-icon> Add New User</button>
  <button mat-raised-button color="accent" (click)="completeClick()" [disabled]="!dataSource.data.length">
    Complete
  </button>
</mat-dialog-actions>
