import {Component, Input, OnInit} from '@angular/core';
import {Survey, SurveyResultsQuestion} from "../../../../../model/common";
import {AuthApiService} from "../../../../../core/auth-api.service";

@Component({
  selector: 'app-survey-results-question',
  templateUrl: './survey-results-question.component.html',
  styleUrls: ['./survey-results-question.component.scss']
})
export class SurveyResultsQuestionComponent implements OnInit {

  @Input("survey") survey: Survey;
  @Input("question") question: SurveyResultsQuestion;
  @Input('sectionNumber') sectionNumber: Number;
  @Input('questionNumber') questionNumber: Number;
  @Input('showResultsBelowSafeHarbor') showResultsBelowSafeHarbor: boolean = false;

  questionTypeDescription = '';

  constructor(
    private auth: AuthApiService
  ) { }

  ngOnInit() {
    if (this.auth.isUser()) {
      this.showResultsBelowSafeHarbor = false;
    }
    this.questionTypeDescription = this.getQuestionType(this.question);
  }

  getQuestionType(question: SurveyResultsQuestion) {

    switch (question.block.type) {
      case "tripetto-block-checkbox":
        return "Checkbox";
      case "tripetto-block-number":
        return "Number input";
      case "tripetto-block-text":
        return "Text input";
      case "tripetto-block-textarea":
        return "Text area input";
      case "block-checkboxes":
        return "Select multiple answers (checkboxes)";
      case "tripetto-block-radiobuttons":
        return "Select one answer (radio button)";
      case "tripetto-block-dropdown":
        return "Select one answer (drop down)";
      case "block-matrix-checkboxes":
        return "Select multiple answers per row for multiple rows (checkboxes)";
      case "block-matrix-radiobuttons":
        return "Select one answer per row for multiple rows (radio button)";
      case "block-matrix-dropdowns":
        let options = (<Array<any>>question.block.dropdownOptions).map(opt => `"${opt.name}"`).join(', ');
        return `Select one answer per row for multiple rows (drop down) with options ${options}`;
      default:
        return null;
    }
  }

  isLowAccuracyQuestion() {
    if (!this.question.results) {
      return true;
    }
    return this.question.results['answered_count'] < this.survey.safe_harbor_count;
  }

  failToSafeHarbor(): boolean {
    if (this.showResultsBelowSafeHarbor) {
      return false;
    }

    return this.isLowAccuracyQuestion();
  }

}
