<mat-card class="m-3 p-3 w-100">
  <mat-card-header>

    <mat-card-title [id]="question.id">
      {{sectionNumber ? sectionNumber + '.' : ''}}{{questionNumber ? questionNumber + '.' : ''}} {{question.name}}
    </mat-card-title>


    <mat-card-subtitle *ngIf="question.description">
      {{question.description}}
    </mat-card-subtitle>
    <mat-card-subtitle class="font-weight-bold" *ngIf="question.results">
      <span class="d-flex align-items-center ml-3" [ngClass]="{'text-danger':isLowAccuracyQuestion()}"
            [matTooltip]="isLowAccuracyQuestion() ? 'Collected data is not sufficient to render an accurate result.' : null">
       Answered {{question.results['answered_count']}} times
      </span>
      <span class="ml-3">Skipped {{question.results['skipped_count']}} times</span>
    </mat-card-subtitle>
    <mat-card-subtitle *ngIf="questionTypeDescription">
      <span class="ml-3">{{questionTypeDescription}}</span>
    </mat-card-subtitle>
    <mat-card-subtitle *ngIf="question.conditions" class="font-italic">
      This question is applicable when the following conditions are met:
      <ul>
        <li *ngFor="let condition of question.conditions">
          {{condition}}
        </li>
      </ul>
    </mat-card-subtitle>


  </mat-card-header>

  <mat-card-content class="p-3">

    <div *ngIf="failToSafeHarbor()">
      <div class="text-center">
        <h1 class="d-block">
          <i class="material-icons" style="font-size: 3em; color: #BDBDBD;">
            sentiment_dissatisfied
          </i></h1>
        <h3 class="text-dark">
          We are sorry but the collected data is not sufficient to render an accurate result.
        </h3>
      </div>
    </div>

    <ng-container *ngIf="!failToSafeHarbor() && question.results" [ngSwitch]="question.block.type">

      <app-block-tripetto-number-result
        *ngSwitchCase="'tripetto-block-number'"
        [question]="question"
      ></app-block-tripetto-number-result>

      <app-block-tripetto-text-result
        *ngSwitchCase="'tripetto-block-text'"
        [question]="question"
      ></app-block-tripetto-text-result>

      <app-block-tripetto-text-result
        *ngSwitchCase="'tripetto-block-textarea'"
        [question]="question"
      ></app-block-tripetto-text-result>

      <app-block-tripetto-text-result
        *ngSwitchCase="'tripetto-block-url'"
        [question]="question"
      ></app-block-tripetto-text-result>

      <app-block-tripetto-dropdown-result
        *ngSwitchCase="'tripetto-block-dropdown'"
        [question]="question"
      ></app-block-tripetto-dropdown-result>

      <app-block-tripetto-radiobuttons-result
        *ngSwitchCase="'tripetto-block-radiobuttons'"
        [question]="question"
      ></app-block-tripetto-radiobuttons-result>

      <app-block-checkbox-result
        *ngSwitchCase="'tripetto-block-checkbox'"
        [question]="question"
      ></app-block-checkbox-result>

      <app-block-matrix-checkboxes-result
        *ngSwitchCase="'block-matrix-checkboxes'"
        [question]="question"
      ></app-block-matrix-checkboxes-result>

      <app-block-matrix-radiobuttons-result
        *ngSwitchCase="'block-matrix-radiobuttons'"
        [question]="question"
      ></app-block-matrix-radiobuttons-result>

      <app-block-matrix-dropdowns-result
        *ngSwitchCase="'block-matrix-dropdowns'"
        [question]="question"
      ></app-block-matrix-dropdowns-result>

      <app-block-checkboxes-result
        *ngSwitchCase="'block-checkboxes'"
        [question]="question"
      ></app-block-checkboxes-result>

      <div *ngSwitchDefault class="alert-danger">Not implemented question type: {{question.block.type}}</div>

    </ng-container>

  </mat-card-content>

</mat-card>

