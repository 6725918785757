import {Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ViewChildren, QueryList} from '@angular/core';
import {ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {SurveyService} from "../../../../../core/survey.service";
import {AuthApiService} from "../../../../../core/auth-api.service";
import {ConfigService} from "../../../../../core/config.service";
import {ED, Survey, SurveyResponse, SurveyResultsSection,
  SurveyDefinition, SurveySection, SurveySectionResponse} from "../../../../../model/common";
import {IDefinition, Import} from 'tripetto-collector';
import {MessageComponent} from "../../../../../component/message";
import {QuestionGroup} from "../../../../../components/survey-editor/survey-editor.component";

@Component({
  selector: 'app-survey-response-print',
  templateUrl: './survey-response-print.component.html',
  styleUrls: ['./survey-response-print.component.scss'],
  encapsulation: ViewEncapsulation.None,
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SurveyResponsePrintComponent implements OnInit {

  @ViewChild('message') message: MessageComponent;
  @ViewChildren('collector') collectors: QueryList<ElementRef>;
  @ViewChildren('occupationCollector') occupationCollectors: QueryList<ElementRef>;

  EDPath = this.auth.getEDPath();
  ED: ED;

  public surveyDefinition: SurveyDefinition = null;
  public survey: Survey = null;
  public surveyResponse: SurveyResponse = null;
  public questionGroups: Array<QuestionGroup> = [];
  public responseIndustries = [];
  public compensationSectionIndex = -1;

  compensationSectionResults: SurveyResultsSection;

  constructor(
    private route: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    public surveyService: SurveyService,
    private hostRef: ElementRef,
    private auth: AuthApiService,
    private configService: ConfigService
  ) { }

  ngOnInit() {
    this.ED = this.auth.user.ed;

    setTimeout(() => {
      this.configService.setSettings({printMode: true});
    }, 0);

    this.init();
  }

  public init() {
    const surveyId = this.route.snapshot.paramMap.get('survey_id');
    const responseId = this.route.snapshot.paramMap.get('response_id');

    if (surveyId && responseId) {
      this.message.renderApiError();
      this.surveyService.getResponse(surveyId, responseId).subscribe(
        r => {
         const response = <SurveyResponse>r;
         this.initSurveyResponse(response);
        },
        e => {
          this.message.renderApiError(e);
        }
      );
    }
  }

  initSurveyResponse(response: SurveyResponse) {
    this.surveyResponse = response;

    this.surveyDefinition = this.surveyResponse.survey.survey_definition;
    this.survey = this.surveyResponse.survey;
    this.compensationSectionIndex = this.surveyResponse.response.sections.findIndex(section => section.is_compensation_survey);

    if (this.compensationSectionIndex > -1) {
      this.responseIndustries = this.initResponseIndustries(
        this.surveyResponse.response.sections[this.compensationSectionIndex].industries);
    }

    this.questionGroups = this.initTableOfContents();
    setTimeout(() => {
      this.initCollectors();
      this.initOccupationCollectors();
      this.changeDetector.detach();
    }, 0);
  }

  initCollectors() {
    if (this.collectors.length === 0) {
      return;
    }

    let counter = 0;
    for (let i = 0; i < this.surveyDefinition.definition.length; i++) {
      const definition = this.surveyDefinition.definition[i].definition;
      const section = this.surveyResponse.response.sections[i];
      if (section.is_compensation_survey) {
        continue;
      }
      if (counter < this.collectors.length) {
        this.initCollector(this.collectors.toArray()[counter], definition, section);
      }
      counter++;
    }
  }

  initOccupationCollectors() {
    if (this.occupationCollectors.length === 0) {
      return;
    }

    let counter = 0;
    for (let i = 0; i < this.responseIndustries.length; i++) {
      const industry = this.responseIndustries[i];
      for (let j = 0; j < industry.occupations.length; j++) {
        const occupation = industry.occupations[j];
        if (counter < this.occupationCollectors.length) {
          this.initCollector(
            this.occupationCollectors.toArray()[counter],
            this.surveyDefinition.definition[this.compensationSectionIndex].definition,
            occupation.state
          );
        }
        counter++;
      }
    }
  }

  initCollector(collector, definition: IDefinition, state?: SurveySectionResponse) {
    collector.mode = 'progressive';
    collector.set('enumerators', true);
    collector.set('preview', true);
    collector.set('read-only', true);
    collector.definition = definition;

    if (definition && state && state['fields']) {
      const importStatus = Import.fields(collector.instance, state['fields']['fields']);
    }
  }

  initResponseIndustries(industries) {
    const responseIndustries = [];
    industries.forEach(industry => {
      let answered = false;
      const answeredOccupations = [];
      industry.occupations.forEach(occupation => {
        if (occupation.hasOwnProperty('state')) {
          answered = true;
          answeredOccupations.push(occupation);
        }
      });
      if (answered) {
        responseIndustries.push({
          id: industry.id,
          title: industry.title,
          occupations: answeredOccupations,
        });
      }
    });
    return responseIndustries;
  }

  initTableOfContents(): QuestionGroup[] {
    const questionGroups: QuestionGroup[] = [];
    const surveyDefinition = this.surveyDefinition;

    if (surveyDefinition.definition && surveyDefinition.definition.length) {
      surveyDefinition.definition.forEach((section, index) => {

        const questionGroup = {
          name: section.definition.name,
          questions: [],
          is_compensation_survey: section.is_compensation_survey,
        };

        this.addClusterQuestions(section.definition.clusters, questionGroup);

        questionGroups.push(questionGroup);
      });
    }

    return questionGroups;
  }

  addClusterQuestions(clusters, questionGroup) {
    clusters.forEach(cluster => {
      cluster.nodes.forEach(node => {
        if (node && node.block) {
          questionGroup.questions.push({
            viewValue: node.name,
            value: node.id
          });
        }
      });

      if (cluster.hasOwnProperty('branches') && Array.isArray(cluster.branches)) {
        cluster.branches.forEach(branch => {
          this.addClusterQuestions(branch.clusters, questionGroup);
        });
      }
    });
  }

  sanitizeHref(key: string) {
    return key.replace(/[ /()-]/g, '_');
  }

  scrollToElement(elementRef): void {
    const element = document.getElementById(elementRef);
    if (element) {
      element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
    }
  }

}
