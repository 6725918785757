<app-message
    #message
    [opened]="false"
>
  <button app-message-actions mat-icon-button (click)="message.close()">
    <mat-icon>cancel</mat-icon>
  </button>
</app-message>

<div class="container-fluid cover-page" *ngIf="survey">
  <div class="row text-center">
    <h1>{{survey.title}}</h1>
  </div>
  <div class="row mt-5">
    <div class="col text-center">
      <app-ed-logo [logoPath]="ED.logo_url"></app-ed-logo>
    </div>
  </div>

  <div class="row mt-5" *ngIf="survey.description">
    <div class="col">
      <div class="container" [innerHTML]="survey.description"></div>
    </div>
  </div>

  <div class="row mt-5" *ngIf="survey.cover_page_text">
    <div class="col">
      <div class="container" [innerHTML]="survey.cover_page_text"></div>
    </div>
  </div>

</div>


<div class="container-fluid" *ngIf="surveyResponse">

  <div class="row table-of-contents">
    <div class="col">
      <h1>Table of contents</h1>
      <ul>
        <ng-container *ngFor="let questionGroup of questionGroups; let i = index">
          <li mat-list-item>
            <a href="#section-{{i+1}}" (click)="!!scrollToElement('section-'+(i+1))">
              {{i + 1}}. {{questionGroup.name}}
            </a>

            <ul *ngIf="!questionGroup.is_compensation_survey">
              <li *ngFor="let question of questionGroup.questions; let qi = index">
                <!-- <a href="#{{question['value']}}" (click)="!!scrollToElement(question['value'])"> -->
                <a href="#section-{{i+1}}" (click)="!!scrollToElement('section-'+(i+1))">
                  {{i + 1}}.{{qi + 1}} {{question.viewValue}}
                </a>
              </li>
            </ul>

            <ul *ngIf="questionGroup.is_compensation_survey">
              <li *ngFor="let industry of responseIndustries; let ii = index">
                <ng-container *ngIf="industry.occupations.length">
                  <a href="#{{sanitizeHref(industry.title)}}" (click)="!!scrollToElement(sanitizeHref(industry.title))">
                    {{i + 1}}.{{ii + 1}} {{industry.title}}
                  </a>
                    <ul>
                      <li *ngFor="let occupation of industry.occupations; let oi = index">
                        <a href="#{{sanitizeHref(industry.title + '__' + occupation.title)}}" (click)="!!scrollToElement(sanitizeHref(industry.title + '__' + occupation.title))">
                          {{i + 1}}.{{ii + 1}}.{{oi + 1}} {{occupation.title}}
                        </a>
                      </li>
                    </ul>
                </ng-container>
              </li>
            </ul>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>

  <ng-container *ngFor="let definition of surveyDefinition.definition; let i = index">
    <div id="section-{{i+1}}">
      <h1 class="ml-3 mt-5">
        {{i+1}}. {{definition.definition.name}}
      </h1>
    </div>
    <ng-container *ngIf="!definition.is_compensation_survey">
      <tripetto-collector #collector
        [progressbar]="false"
        [buttons]="false"
      ></tripetto-collector>
    </ng-container>
  
    <ng-container *ngIf="definition.is_compensation_survey">
      <ng-container *ngFor="let industry of responseIndustries; let ii = index">
        <h2 class="ml-5 mt-5" id="{{sanitizeHref(industry.title)}}">
          {{i + 1}}.{{ii + 1}} {{industry.title}}
        </h2>
        <ng-container *ngFor="let occupation of industry.occupations; let oi = index">
          <h3 class="ml-5 mt-5" id="{{sanitizeHref(industry.title + '__' + occupation.title)}}">
            {{i + 1}}.{{ii + 1}}.{{oi + 1}} {{occupation.title}}
          </h3>
            <tripetto-collector #occupationCollector
              [progressbar]="false"
              [buttons]="false"
            ></tripetto-collector>
        </ng-container>
      </ng-container>
    </ng-container>

  </ng-container>

</div>
<div id="end_of_page">&nbsp;</div>
