import {Component, OnInit} from '@angular/core';
import {AuthApiService} from "../../../core/auth-api.service";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  isUser: Boolean;

  constructor(private authService: AuthApiService) {
  }

  ngOnInit() {
    this.isUser = this.authService.isUser();
  }


}
