<h2 mat-dialog-title class="mb-2">
  Invite Users
  <button mat-icon-button type="button" color="primary" (click)="cancel()">
    <mat-icon>close</mat-icon>
  </button>
</h2>

<mat-dialog-content class="mat-typography">
  <app-message #message [opened]="false" class="mat-typography">
    <button app-message-actions mat-icon-button (click)="message.close()">
      <mat-icon>cancel</mat-icon>
    </button>
  </app-message>
  <app-step-invite-users #appStepInviteUsers [selectedUsers]="surveyUsers"
                         (onUsersSelected)="onUsersSelected($event)"></app-step-invite-users>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-stroked-button color="primary" class="mr-2" (click)="appStepInviteUsers.inviteUsers()">
    <mat-icon>person_add</mat-icon>
    Invite Users
  </button>
  <button mat-raised-button color="primary" (click)="save(saveBtn)" #saveBtn>
    <mat-icon>save</mat-icon>
    Save
  </button>
</mat-dialog-actions>
