<app-message
    #message
    [opened]="false"
>
  <button type="button" app-message-actions mat-icon-button (click)="message.close()">
    <mat-icon>cancel</mat-icon>
  </button>
</app-message>


<div class="container-fluid cover-page" *ngIf="surveyResults">

  <div class="row text-center">
    <h1>{{survey.title}}</h1>
  </div>
  <div class="row mt-5">
    <div class="col text-center">
      <app-ed-logo [logoPath]="ED.logo_url"></app-ed-logo>
    </div>
  </div>

  <div class="row mt-5" *ngIf="survey.description">
    <div class="col">
      <div class="container" [innerHTML]="survey.description"></div>
    </div>
  </div>

  <div class="row mt-5" *ngIf="survey.cover_page_text">
    <div class="col">
      <div class="container" [innerHTML]="survey.cover_page_text"></div>
    </div>
  </div>

</div>

<div class="container-fluid cover-page" *ngIf="surveyResults">
  <div class="row text-center">
    <h1>Businesses</h1>
  </div>
  <div class="row">
    <div class="col">
      <ul style="columns: 2; column-gap: 2em;">
        <li
          *ngFor="let business of surveyResults.businesses"
        >{{business.name}}</li>
      </ul>
    </div>
  </div>
</div>


<div class="container-fluid" *ngIf="surveyResults">

  <div class="row table-of-contents">
    <div class="col">
      <h1>Table of contents</h1>
      <ul>
        <ng-container *ngFor="let section of surveyResults.sections; let i = index">
          <li mat-list-item>
            <a href="#section-{{i+1}}" (click)="!!scrollToElement('section-'+(i+1))">
              {{i + 1}}. {{section.name}}
            </a>
            <ul *ngIf="!section.is_compensation_survey">
              <li *ngFor="let question of section.questions; let qi = index">
                <a href="#{{question['id']}}" (click)="!!scrollToElement(question['id'])">
                  {{i + 1}}.{{qi + 1}} {{question.name}}
                </a>
              </li>
            </ul>
            <ul *ngIf="section.is_compensation_survey">
              <li *ngFor="let industry of compensationSectionResults.industries; let ii = index">
                <ng-container *ngIf="industry.occupations.length">
                  <a href="#{{sanitizeHref(industry.title)}}" (click)="!!scrollToElement(sanitizeHref(industry.title))">
                    {{i + 1}}.{{ii + 1}} {{industry.title}}
                  </a>
                    <ul>
                      <li *ngFor="let occupation of industry.occupations; let oi = index">
                        <a href="#{{sanitizeHref(industry.title + '__' + occupation.title)}}" (click)="!!scrollToElement(sanitizeHref(industry.title + '__' + occupation.title))">
                          {{i + 1}}.{{ii + 1}}.{{oi + 1}} {{occupation.title}}
                        </a>
                      </li>
                    </ul>
                </ng-container>
              </li>
            </ul>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>

  <div class="row survey-section" *ngFor="let section of surveyResults.sections; index as i">
      <div class="col" id="section-{{i+1}}">
        <ng-container *ngIf="!section['is_compensation_survey']">
          <app-survey-results-section
            [survey]="survey"
            [sectionResult]="section"
            [sectionNumber]="i"
            [showResultsBelowSafeHarbor]="showResultsBelowSafeHarbor"
          ></app-survey-results-section>
        </ng-container>
        <ng-container *ngIf="section['is_compensation_survey']">
          <app-survey-print-results-compensation
            [survey]="survey"
            [results]="compensationSectionResults"
            [sectionNumber]="i"
            [showResultsBelowSafeHarbor]="showResultsBelowSafeHarbor"
          ></app-survey-print-results-compensation>
        </ng-container>
      </div>
  </div>

</div>
<div id="end_of_page">&nbsp;</div>
