<app-message
    #message
    [opened]="false"
    class="m-2"
>
  <button app-message-actions mat-icon-button (click)="message.close()">
    <mat-icon>cancel</mat-icon>
  </button>
</app-message>

<div class="row">
  <mat-card-title>Job description</mat-card-title>
  <p
    [innerHTML]="occupation.description"
  >
  </p>
</div>

<ng-container *ngFor="let question of occupationQuestions;index as i">
  <div class="row no-gutters" *ngIf="regularQuestions.includes(question['alias'])">
    <div class="col">
      <app-survey-results-question
        [survey]="survey"
        [question]="question"
        [showResultsBelowSafeHarbor]="showResultsBelowSafeHarbor"
      >
      </app-survey-results-question>
    </div>
  </div>
</ng-container>


<mat-card>
  <mat-card-title>Compensation data</mat-card-title>
  <mat-card-content>

    <div *ngIf="failToSafeHarborSection()">
      <div class="text-center">
        <h1 class="d-block">
          <i class="material-icons" style="font-size: 3em; color: #BDBDBD;">
            sentiment_dissatisfied
          </i></h1>
        <h3 class="text-dark">
          We are sorry but the collected data is not sufficient to render an accurate result.
        </h3>
      </div>
    </div>

    <table *ngIf="!failToSafeHarborSection()" class="mat-table">
      <thead>
      <tr *ngIf="groupQuestions[0]" class="mat-header-row">
        <th class="mat-header-cell">&nbsp;</th>
        <th class="mat-header-cell" *ngFor="let stat of groupQuestions[0]['results']['stats']">{{stat['label']}}</th>
      </tr>
      </thead>
      <tbody>
      <tr class="mat-row" *ngFor="let question of groupQuestions">
        <th class="mat-header-cell">{{question.name}}</th>
        <td class="mat-cell" *ngFor="let stat of question['results']['stats']">
          {{failToSafeHarborQuestion(question) ? null : stat['value']}}
        </td>
      </tr>
      </tbody>
    </table>
  </mat-card-content>
</mat-card>
    
