import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanDeactivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthApiService} from './core/auth-api.service';
import {map} from 'rxjs/operators';
import {EdService} from "./core/ed.service";
import {ED} from "./model/common";
import {DialogService} from './component';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private auth: AuthApiService) {
  }

  canActivate() {
    return this.canActivateChild();
  }

  canActivateChild() {
    return this.auth.checkAuth().pipe(map(r => {
      if (r) {
        return true;
      } else {
        this.router.navigate(['/signin']);
        return false;
      }
    }));
  }
}

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  constructor(private router: Router, private auth: AuthApiService) {
  }

  canActivate() {
    return this.canActivateChild();
  }

  canActivateChild() {

    if (this.auth.isSuperAdmin()) {
      return true;
    }

    this.router.navigate(['/404']);
    return false;
  }
}

@Injectable({
  providedIn: 'root'
})
export class EDPathGuard implements CanActivate {

  constructor(private router: Router, private auth: AuthApiService, private edService: EdService) {
  }

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot) {
    return this.canActivateChild(next, state);
  }

  canActivateChild(next: ActivatedRouteSnapshot,
                   state: RouterStateSnapshot) {

    const edPath = next.params['ed-path'];

    if (this.auth.isSuperAdmin() && !this.auth.getED()) {
      this.edService.all().subscribe((r: ED[]) => {
        let ed = r.filter(v => {
          return v['path'] == edPath
        });
        // console.log(ed);
      })
    }


    if (!this.auth.isSuperAdmin() && edPath != this.auth.getED()) {
      this.router.navigate(['/404']);
      return false;
    }

    return true;
  }
}


@Injectable({
  providedIn: 'root'
})
export class EDMemberGuard implements CanActivate {

  constructor(private router: Router, private auth: AuthApiService) {
  }

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot) {
    return this.canActivateChild(next, state);
  }

  canActivateChild(next: ActivatedRouteSnapshot,
                   state: RouterStateSnapshot) {


    if (!this.auth.isEDMember()) {
      this.router.navigate(['/404']);
      return false;
    }

    return true;
  }
}

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable({
  providedIn: 'root'
})
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {

  constructor(
    private dialog: DialogService,
  ) { }

  canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
    return component.canDeactivate() ? true :
      this.dialog.confirm({
        title: 'Navigation warning',
        message: 'You have unsaved changes. Press Cancel to stay on the page, or OK to navigate away and lose these changes.',
        cancelButton: 'Cancel',
        acceptButton: 'OK'
      }).afterClosed();
  }
}



