import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {MessageComponent} from "../../../../../component/message";
import {Survey} from "../../../../../model/common";

@Component({
  selector: 'app-step-survey-details-form',
  templateUrl: './step-survey-details-form.component.html',
  styleUrls: ['./step-survey-details-form.component.scss'],
})
export class StepSurveyDetailsFormComponent implements OnInit, OnDestroy {

  @ViewChild('message') messageBox: MessageComponent;

  public form: FormGroup;

  private _survey: Survey;
  public use_custom_survey_invitation = false;

  @Input() hideHeader: boolean;

  @Input() set survey(survey: Survey) {
    this._survey = survey;
    this.initForm();
  }

  get survey(): Survey {
    return this._survey;
  }

  @Output() surveyChange = new EventEmitter<Survey>();

  @Output() onFormChange = new EventEmitter();

  validatorSub: Subscription;

  formErrors = {
    title: '',
    start_date: '',
    end_date: '',
    show_businesses_list_in_results: '',
    safe_harbor_count: '',
  };

  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    this.initForm();
  }

  ngOnDestroy(): void {
    if (this.validatorSub) {
      this.validatorSub.unsubscribe();
    }
  }

  initForm() {
    if (this.form) {
      if (this._survey && this._survey.title) {
        this.form.patchValue(this._survey);
      } else {
        this.form.reset();
      }

      return;
    }

    this._survey = this._survey || <Survey>{};

    if (this.survey.custom_survey_invitation) {
      this.use_custom_survey_invitation = true;
    }

    this.form = this.fb.group({
      title: [this.survey.title, [Validators.required]],
      video_link: [this.survey.video_link, []],
      description: [this.survey.description, [Validators.required]],
      start_date: [this.survey.start_date ? this.survey.start_date.substr(0, 10) : null, [Validators.required]],
      end_date: [this.survey.end_date ? this.survey.end_date.substr(0, 10) : null, [Validators.required]],
      safe_harbor_count: [this.survey.safe_harbor_count, [Validators.required]],
      show_businesses_list_in_results: [this.survey.show_businesses_list_in_results, [Validators.required]],
      custom_survey_invitation: [this.survey.custom_survey_invitation, []],
    });

    this.form.valueChanges.subscribe(() => {
      this.onFormChange.emit({value: this.form.getRawValue(), valid: this.form.valid});
      if (this.form.valid) {
        this._survey = this.form.getRawValue();
        this.surveyChange.emit(this._survey);
      }
    });

  }

  onUseCustomSurveyInvitationChanged() {
    if (this.use_custom_survey_invitation) {
      this.survey.custom_survey_invitation =
      'We created this survey system to give you the most up to date and trustworthy data concerning wages and benefits.';
    } else {
      this.survey.custom_survey_invitation = null;
    }
  }

}
