import {Component, Input, OnInit} from '@angular/core';
import {Survey, SurveyResultsSection, SurveySectionResponse} from "../../../../../model/common";

@Component({
  selector: 'app-survey-results-section',
  templateUrl: './survey-results-section.component.html',
  styleUrls: ['./survey-results-section.component.scss']
})
export class SurveyResultsSectionComponent implements OnInit {

  @Input('survey') survey: Survey;
  @Input('sectionResult') sectionResult: SurveyResultsSection;
  @Input('sectionNumber') sectionNumber: Number;
  @Input('showResultsBelowSafeHarbor') showResultsBelowSafeHarbor: boolean = false;

  constructor() { }

  ngOnInit() {
    this.sectionNumber = this.sectionNumber || 0;
  }

}
