<div class="mat-typography">
    <div class="select-user-header">
        <div class="row align-items-center mb-2">
            <div class="col-md-8">
                <strong class="text-uppercase text-primary">Invite Users</strong><br>
                <strong *ngIf="!selectedUsers">
                    All previous survey takers are automatically added to this list. Deselect any user you do NOT want
                    to take this survey and they will not be allowed access to fill out the survey form.
                </strong>
            </div>
            <div class="col-md-3 ml-md-auto">
                <button color="primary" mat-stroked-button class="w-100" (click)="inviteUsers()">
                    <mat-icon>person_add</mat-icon>
                    Invite Users
                </button>
            </div>
        </div>
    </div>

    <div class="user-select-table">
        <div class="user-select-filters mt-3">
            <mat-form-field class="w-100">
                <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search Name, Email, Business name...">
            </mat-form-field>
        </div>

        <table class="w-100" mat-table matSort [dataSource]="dataSource">

            <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    <mat-checkbox (change)="$event ? masterToggle() : null"
                                  [checked]="selection.hasValue() && isAllSelected()"
                                  [indeterminate]="selection.hasValue() && !isAllSelected()"
                    >
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()"
                                  (change)="$event ? selection.toggle(row) : null"
                                  [checked]="selection.isSelected(row)"
                    >
                    </mat-checkbox>
                </td>
                <td colspan="6" mat-footer-cell *matFooterCellDef>
                    <ng-container *ngIf="!selection.isEmpty()">{{selection.selected.length}} User Selected</ng-container>
                </td>
            </ng-container>

            <ng-container matColumnDef="first_name">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>First Name</th>
                <td mat-cell *matCellDef="let element"> {{element['first_name']}}</td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="last_name">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Last Name</th>
                <td mat-cell *matCellDef="let element"> {{element['last_name']}}</td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="email">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>E-Mail</th>
                <td mat-cell *matCellDef="let element"> {{element['email']}}</td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="business">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Business Name</th>
                <td mat-cell *matCellDef="let element"> {{element.business.name}}</td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>


            <ng-container matColumnDef="surveyed_count">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Times surveyed</th>
                <td mat-cell *matCellDef="let element"> 0 times</td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)"></tr>
            <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>

        </table>
        <mat-paginator [pageSizeOptions]="[10,30,50,100,500]" showFirstLastButtons></mat-paginator>
    </div>

</div>
