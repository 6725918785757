import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {MessageComponent} from "../../../../../component/message";
import {MatPaginator, MatSort, MatSortable, MatTableDataSource} from "@angular/material";
import {User} from "../../../../../model/common";
import {SelectionModel} from "@angular/cdk/collections";
import {EdService} from "../../../../../core/ed.service";
import {DialogService} from "../../../../../component/dialog";
import {InviteUsersDialogComponent} from "./invite-users-dialog/invite-users-dialog.component";
import {debounceTime, map} from "rxjs/operators";
import {Observable} from "rxjs";


@Component({
  selector: 'app-step-invite-users',
  templateUrl: './step-invite-users.component.html',
  styleUrls: ['./step-invite-users.component.scss']
})
export class StepInviteUsersComponent implements OnInit, OnDestroy {

  @Input() message: MessageComponent;

  @Input() selectedUsers: Observable<User[]>;

  @Input() selectAll: boolean = false;

  @Output() onUsersSelected = new EventEmitter();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ['select', 'first_name', 'last_name', 'email', 'business', 'surveyed_count'];
  dataSource = new MatTableDataSource<User>();
  selection = new SelectionModel<User>(true, []);

  constructor(
    private edService: EdService,
    private dialog: DialogService,
  ) {
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.dataSource.sortingDataAccessor = (user: User, sortHeaderId: string) => {
      if (sortHeaderId === 'business') {
        return user[sortHeaderId]['name'];
      } else if (sortHeaderId === 'select') {
        return this.selection.isSelected(user) ? 1 : 0;
      } else {
        return user[sortHeaderId];
      }
    };

    this.dataSource.filterPredicate = (user: User, filter: string) => {
      return [user.first_name, user.last_name, user.email, user.business.name].join(' ').toLowerCase().search(filter) >= 0;
    };

    this.edService.businessUsers().subscribe(r => {
      this.dataSource.data = r['data'];

      if (this.selectedUsers) {
        this.selectedUsers.subscribe(r => {
          let userIDs = {};
          r.map(u => userIDs[u.id] = u);

          this.dataSource.data.forEach(u => {
            if (!userIDs[u.id]) return;
            this.selection.select(u);
          });

          this.sort.sort(<MatSortable>({id: 'select', start: 'desc'}));
          this.dataSource.sort = this.sort;
        });
      }

      if(this.selectAll) {
        this.masterToggle();
      }

    }, e => {
      this.message.renderApiError(e);
    });

    this.selection.changed.pipe(debounceTime(500)).subscribe(() => {
      this.onUsersSelected.emit(this.selection.selected);
    });

  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }


  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  inviteUsers() {
    const dialogRef = this.dialog.open(InviteUsersDialogComponent, {
      disableClose: true,
      panelClass: 'dialog-md'
    });

    const component = dialogRef.componentInstance;
    component.onComplete.subscribe(users => {
      if (!users) return;
      // todo check for duplicate users
      this.dataSource.data = [...users, ...this.dataSource.data];
      users.forEach(u => this.selection.select(u));
      dialogRef.close();
    });
  }

  ngOnDestroy() {
    // this.selectionSub.unsubscribe();
    // this.selectionSub = null;
  }
}
