import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import {MessageComponent} from "../../component/message";
import {Survey, SurveyResponse, User} from "../../model/common";
import {MatPaginator, MatSort, MatTableDataSource} from "@angular/material";
import {SurveyService} from "../../core/survey.service";
import {catchError, map, take} from "rxjs/operators";
import {DialogService} from "../../component/dialog";
import {TakeSurveyComponent} from "../../pages/ed/survey/take-survey/take-survey.component";
import {UtilityService} from "../utility.service";
import {Observable, of, Subscription} from "rxjs";
import {UserService} from "src/app/core/user.service";

@Component({
  selector: 'app-survey-responses-table',
  templateUrl: './survey-responses-table.component.html',
  styleUrls: ['./survey-responses-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SurveyResponsesTableComponent implements OnInit, OnDestroy {

  @Input() survey: Survey = null;
  @ViewChild('message') messageBox: MessageComponent;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @Output() onSurveyResponses = new EventEmitter<SurveyResponse[]>();

  subsCan = new Subscription();
  surveyResponsesObservable;

  displayedColumns: string[] = ['first_name', 'last_name', 'email', 'business', 'status', 'progress', 'updated_at', 'more'];
  dataSource = new MatTableDataSource<SurveyResponse>();

  constructor(
    private surveyService: SurveyService,
    private userService: UserService,
    private dialog: DialogService,
    private util: UtilityService,
    private changeDetector: ChangeDetectorRef
  ) {
  }


  ngOnInit() {
    this.getSurveyResponses();

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.dataSource.sortingDataAccessor = (surveyResponse: SurveyResponse, sortHeaderId: string) => {
      if (sortHeaderId === 'business') {
        return surveyResponse['user'][sortHeaderId]['name'];
      } else if (sortHeaderId === 'updated_at') {
        return surveyResponse[sortHeaderId];
      } else if (sortHeaderId === 'status') {
        return surveyResponse[sortHeaderId]['code'];
      } else if (sortHeaderId === 'progress') {
        return this.getSurveyProgress(surveyResponse);
      } else {
        return surveyResponse['user'][sortHeaderId];
      }
    };

    this.dataSource.filterPredicate = (surveyResponse: SurveyResponse, filter: string) => {
      return [surveyResponse.user.first_name, surveyResponse.user.last_name, surveyResponse.user.email, surveyResponse.user.business.name].join(' ').search(filter) > 0;
    };
  }

  getSurveyResponses() {
    this.surveyResponsesObservable = this.surveyService.getResponses(this.survey.id).pipe(map(r => {
      this.dataSource.data = <SurveyResponse[]>r;
      this.survey.responses = this.dataSource.data;
      this.onSurveyResponses.emit(this.dataSource.data);

    }), catchError((err, caught: Observable<any>) => {
      this.messageBox.renderApiError(err);
      return of([]);
    }));

    if (this.survey.responses && this.survey.responses.length) {
      this.dataSource.data = this.survey.responses;
      this.onSurveyResponses.emit(this.dataSource.data);
    } else {
      this.surveyResponsesObservable.subscribe();
    }
  }

  removeUserFromSurvey(surveyResponse: SurveyResponse) {
    this.userService.removeUserFromSurvey(surveyResponse.user_id, surveyResponse).subscribe(
      r => {
        this.messageBox.renderSuccess({message: "Success!"});
        this.surveyResponsesObservable.subscribe();
      },
      e => {
        this.messageBox.renderApiError(e);
      }
    );
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  viewResponses(surveyResponse: SurveyResponse) {
    this.openSurveyResponse(surveyResponse, true);
  }

  openSurveyResponse(surveyResponse: SurveyResponse, readOnly = true) {
    this.surveyService.getResponse(surveyResponse.survey_id, surveyResponse.id).subscribe(r => {
      let response = <SurveyResponse>r;
      const dialogRef = this.dialog.open(TakeSurveyComponent, {
        panelClass: 'dialog-lg',
        disableClose: false
      });
      dialogRef.componentInstance.isModal = true;

      if (!readOnly) {
        dialogRef.beforeClosed().pipe(take(1)).subscribe(r => {
          this.surveyResponsesObservable.subscribe();
        });
      }
      dialogRef.componentInstance.readOnly = readOnly;
      dialogRef.componentInstance.initFromSurveyResponse(response);

    }, e => {
      this.messageBox.renderApiError(e);
    });
  }

  takeSurvey(surveyResponse: SurveyResponse) {
    this.openSurveyResponse(surveyResponse, false);
  }

  sendReminder(surveyResponse: SurveyResponse) {
    this.messageBox.renderApiError();
    this.surveyService.inviteUsers(surveyResponse.survey_id, [surveyResponse.user]).subscribe(r => {
      this.messageBox.renderSuccess({message: "Notification sent!"});
    }, e => {
      this.messageBox.renderApiError(e);
    });
  }

  getSurveyProgress(surveyResponse: SurveyResponse) {
    return SurveyService.getSurveyProgress(surveyResponse);
  }

  isProgressBarEligible(surveyResponse: SurveyResponse): boolean {
    return SurveyService.isProgressBarEligible(surveyResponse);
  }

  ngOnDestroy() {
    this.subsCan.unsubscribe();
  }

  editUser(user) {
    this.subsCan.add(this.util.editUser(user ? user : <User>{}).subscribe(() => {
      this.surveyResponsesObservable.subscribe();
    }));
  }

  resendInvitation(surveyResponse: SurveyResponse) {
    this.messageBox.renderApiError();
    this.surveyService.resendInvitations(surveyResponse.survey_id, [surveyResponse.user]).subscribe(r => {
      this.messageBox.renderSuccess({message: "Notification sent!"});
    }, e => {
      this.messageBox.renderApiError(e);
    });
  }

  private updateResponseExcludedFlag(surveyResponse: SurveyResponse, flag: boolean) {
    this.messageBox.renderApiError();
    this.surveyService.setResponseExcludedFlag(surveyResponse, flag).subscribe(r => {
      this.messageBox.renderSuccess({message: "Success!"});
      this.surveyResponsesObservable.subscribe();
    }, e => {
      this.messageBox.renderApiError(e);
    });
  }

  excludeFromResults(surveyResponse: SurveyResponse) {
    this.updateResponseExcludedFlag(surveyResponse, true);
  }

  includeInResults(surveyResponse: SurveyResponse) {
    this.updateResponseExcludedFlag(surveyResponse, false);
  }

}
