import {Component, OnDestroy, OnInit, ViewChild, HostListener} from '@angular/core';
import {Observable} from 'rxjs';
import {ComponentCanDeactivate} from "../../../../app.guard";
import {MessageComponent} from '../../../../component/message';
import {MatHorizontalStepper} from '@angular/material';
import {Occupation, Survey, SurveyDefinition, User} from '../../../../model/common';
import {BehaviorSubject, Subscription} from 'rxjs';
import {DialogService} from '../../../../component/dialog';
import {SurveyService} from '../../../../core/survey.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthApiService} from '../../../../core/auth-api.service';

export interface UsersByBusiness {
  business: string;
  users: User[];
  count?: number;
}

@Component({
  selector: 'app-start-survey',
  templateUrl: './start-survey.component.html',
  styleUrls: ['./start-survey.component.scss']
})
export class StartSurveyComponent implements OnInit, OnDestroy, ComponentCanDeactivate {

  @ViewChild('message') messageBox: MessageComponent;
  @ViewChild('stepper') stepper: MatHorizontalStepper;

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    if(this.warningType == false){
      return true;
    }
    return false;
  }

  constructor(
    private dialog: DialogService,
    private surveyService: SurveyService,
    private router: Router,
    private auth: AuthApiService,
    private route: ActivatedRoute,
  ) { }

  surveyTemplate: SurveyDefinition;
  survey: Survey = {} as Survey;
  users: User[] = [];

  usersByBusiness = new BehaviorSubject<UsersByBusiness[]>(null);
  usersBusinessTableColumns = ['business', 'count'];

  occupations: Occupation[] = [];
  routeSub: Subscription;

  duplicateSurveyID = null;

  warningType = true;

  ngOnInit() {
    this.stepper.linear = false;

    this.routeSub = this.route.queryParams.subscribe(params => {
      if (params.hasOwnProperty('duplicateSurveyID')) {
        this.duplicateSurveyID = params['duplicateSurveyID'];
      }
    });
  }

  onUsersSelected(users) {
    this.users = users;
    this.usersByBusiness.next(this.groupUsersByBusiness(users));
  }

  groupUsersByBusiness(users): UsersByBusiness[] {
    let byBusiness: { [business: string]: UsersByBusiness } = {};

    users.forEach(user => {
      byBusiness[user.business.name] = byBusiness[user.business.name] || {
        business: user.business.name,
        users: [],
        count: 0
      } as UsersByBusiness;
      byBusiness[user.business.name]['users'].push(user);
      byBusiness[user.business.name]['count']++;
    });

    return Object.values(byBusiness).sort((a, b) => a['business'].localeCompare(b['business']));
  }

  onTemplateSelected(template: SurveyDefinition) {
    this.surveyTemplate = template;
    this.occupations = template.occupations;

    this.survey = template.survey ||  {} as Survey;
  }

  onSurveyTemplateEdited(template: SurveyDefinition) {
    this.surveyTemplate = template;

    if (!this.survey.title || !this.survey.title.length) {
      let survey = this.survey;
      survey.title = template.title;
      this.survey = survey;
    }
  }

  onOccupationsChanged(occupations) {
    this.occupations = <Occupation[]>occupations;
  }

  getIndustriesCount(occupations) {
    const c = {};
    occupations.forEach((occupation: Occupation) => {
      if (occupation.industry_id) {
        c[occupation.industry_id] = true;
      } else {
        c[occupation.industry.title] = true;
      }
    });
    return Object.keys(c).length;
  }

  submit() {
    const formatDate = (date: Date|any) => {
      if (date instanceof Date) {
        const y = date.getFullYear();
        const m = (date.getMonth() + 1).toString().padStart(2, '0');
        const d = date.getDate().toString().padStart(2, '0');
        return [y, m, d].join('-');
      }
      return date;
    };

    this.survey.start_date = formatDate(this.survey.start_date);
    this.survey.end_date = formatDate(this.survey.end_date);

    this.messageBox.renderApiError();
    this.surveyService.create({
      surveyTemplate: this.surveyTemplate,
      survey: this.survey,
      users: this.users,
      occupations: this.occupations,
    }).subscribe(r => {

      this.dialog.alert({
        title: 'Success!',
        message: 'Survey is initiated successfully',
        closeButton: 'Close'
      }).afterClosed().subscribe(() => {
        this.warningType = false;
        this.router.navigate(['/']);
      });

    }, e => {
      this.messageBox.renderApiError(e);
    });
  }

  ngOnDestroy(): void {
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
  }

  isSurveyInfoFilled() {
    return this.survey.title && this.survey.start_date && this.survey.end_date;
  }

}
