<mat-card [hidden]="true">
  <mat-card-header>
    <mat-card-title></mat-card-title>
  </mat-card-header>
  <mat-card-actions></mat-card-actions>
</mat-card>
<h2 mat-dialog-title *ngIf="isModal">
  {{_surveyDefinition && _surveyDefinition.title}}
  <button mat-icon-button mat-dialog-close color="primary">
    <mat-icon>close</mat-icon>
  </button>
</h2>
<div class="survey-messages">
  <app-message
    #message
    [opened]="false"
    class="m-2"
  >
    <button app-message-actions mat-icon-button (click)="message.close()">
      <mat-icon>cancel</mat-icon>
    </button>
  </app-message>

  <!--<app-message-->
  <!--[opened]="true"-->
  <!--[color]="'info'"-->
  <!--[label]="'You are viewing the survey in Preview Mode. No changes will be saved.'"-->
  <!--*ngIf="isPreviewMode()"-->
  <!--class="m-2"-->
  <!--&gt;-->
  <!--</app-message>-->

  <app-message
    [opened]="true"
    [color]="'info'"
    [label]="'You are viewing the survey response in read-only mode. No changes will be saved.'"
    *ngIf="readOnly && !isPreviewMode()"
    class="m-2"
  >
  </app-message>
</div>

<div class="mat-dialog-content" [ngClass]="{'modal' : isModal, 'not-modal': !isModal}">

  <div class="app-survey">
    <div class="row no-gutters flex-grow-1 w-100 h-100">
      <div class="col-md-3 column-100">
        <div class="app-survey__navigation">
          <div class="mat-card" *ngIf="_surveyDefinition">
            <div class="mat-card-content">

              <div class="survey-navigation-title sticky-top d-flex align-items-center">
                <div class="d-flex flex-grow-1 flex-column">
                  <div *ngIf="!isPreviewMode()" class="progress-bar-container w-100">
                    <div class="progress progress--20">
                      <div class="progress-bar bg-success"
                           role="progressbar"
                           [style.width]="surveyProgress ? surveyProgress + '%' : 0"
                           aria-valuemin="0"
                           aria-valuemax="100">
                          <span class="progress-bar__label"
                                *ngIf="surveyProgress">
                          {{surveyProgress > 10 ? surveyProgress + '% Complete' : surveyProgress + '%'}}
                          </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div [hidden]="isPreviewMode() || readOnly">
                  <button
                    mat-icon-button
                    color="accent"
                    #saveBtn
                    (click)="save(saveBtn)"
                    matTooltip="Saved {{lastSavedTimestamp | timeAgo}}"
                  ><i
                    class="material-icons">
                    save
                  </i>
                  </button>
                </div>
              </div>


              <ul id="survey-navigation">
                <li
                  class="survey-navigation-item"
                  *ngFor="let surveySection of _surveyDefinition.definition;index as i"
                >
                  <a mat-button (click)="activateSection(surveySection)"
                     *ngIf="surveySection.definition"
                     [title]="surveySection.definition.name || 'Unnamed section'"
                     [color]="surveySection == activeSection ? 'primary' : ''"
                     #link
                  >
                    <mat-icon
                      [ngClass]="{'text-success': isSectionComplete(i)}"
                    >
                      {{link.color ? 'edit' : 'check_circle_outline'}}
                    </mat-icon>
                    {{surveySection.definition.name || "Unnamed section"}}
                  </a>

                  <ng-container
                    *ngIf="surveySection.is_compensation_survey && activeSectionState && activeSectionState.is_compensation_survey; then compensationSurveyNavigation;">
                  </ng-container>

                </li>
                <li class="survey-navigation-item" *ngIf="surveyResponse && surveyResponse.id && !isPreviewMode()">
                  <a mat-flat-button (click)="activateSection(null)"
                     [color]="activeSection === null? 'primary' : ''">
                    <i class="material-icons section-status-icon">
                      equalizer
                    </i>
                    Review & Submit
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <ng-template #compensationSurveyNavigation>
            <ul class="navigation-nested">
              <ng-container *ngFor="let industry of activeSectionState.industries">
                <li *ngIf="industryHasSelectedOccupation(industry)">
                  <span [ngClass]="{
                          'font-weight-bold text-info' : activeSectionState.activeOccupation && activeSectionState.activeOccupation.industry_id == industry.id
                          }"
                  >{{industry.title}}
                  </span>
                  <ul>
                    <ng-container *ngFor="let occupation of industry.occupations">
                      <li *ngIf="occupation.selected">
                        <a
                          mat-button
                          [color]="isActiveOccupation(occupation, industry) ? 'primary' : null"
                          (click)="activateOccupation(occupation, industry)"
                          [title]="occupation.title"
                          #occupationLink
                        >
                          <mat-icon
                            [ngClass]="{'text-success': isOccupationComplete(occupation)}">
                            {{occupationLink.color ? 'edit' : 'check_circle_outline'}}
                          </mat-icon>
                          {{occupation.title}}
                        </a>
                      </li>
                    </ng-container>
                  </ul>
                </li>
              </ng-container>
            </ul>
          </ng-template>
        </div>
      </div>
      <div class="col-md-9 column-100 d-flex flex-column" #surveyQuestionsColumn>

        <div class="mat-card survey-questions-wrapper d-flex flex-column flex-grow-1">
          <div class="mat-card-header mt-2" *ngIf="!isModal && _surveyDefinition">
            <div class="mat-card-title">{{_surveyDefinition.title}}</div>
          </div>

          <mat-toolbar color="warn" *ngIf="prefilled">
            <span>This is a prefilled survey. Please review your answers before submitting!</span>
          </mat-toolbar>

          <div class="mat-card-content d-flex flex-column flex-grow-1">

            <ng-container *ngIf="activeSectionState && activeSectionState.is_compensation_survey">

              <ng-container *ngIf="!activeSectionState.activeOccupation;else regularButton">
                <div class="mat-typography mt-3">
                  <div class="mat-subheading-1 text-center">
                    Click the button to select the occupations used at your business.
                  </div>
                </div>

                <button class="select-occupations-button__no-occupations" mat-raised-button color="primary"
                        (click)="selectIndustriesAndOccupations()">
                  <mat-icon>business</mat-icon>
                  Select Occupations
                </button>
              </ng-container>

              <ng-template #regularButton>
                <button class="mb-2 w-100 select-occupations-button" mat-stroked-button color="primary"
                        (click)="selectIndustriesAndOccupations()">
                  <mat-icon>business</mat-icon>
                  Select Occupations
                </button>
              </ng-template>

            </ng-container>

            <div [hidden]="isCollectorHidden()" class="mat-card survey-section">

              <ng-container
                *ngIf="activeSection">
                <div
                  class="mat-card-title survey-section__title sticky-top">
                  {{activeSection.definition.name}}
                </div>


                <ng-container *ngIf="activeSectionState &&  activeSectionState.is_compensation_survey && activeSectionState.activeOccupation">
                  <div
                    class="mat-card-title survey-section__title sticky-top">
                    {{activeSectionState.activeOccupation.title}}
                  </div>
                  <app-message
                    [icon]="null"
                    [color]="'secondary'"
                    [label]="'Occupation Description'"
                    [sublabel]="occupationDescriptionsCache[activeSectionState.activeOccupation.id]"
                    class="m-1"
                  >
                  </app-message>
                </ng-container>
              </ng-container>



              <div class="mat-card-content" [hidden]="isCollectorHidden()" #surveyQuestions>
                <tripetto-collector #collector
                                    [progressbar]="false"
                                    [buttons]="false"
                ></tripetto-collector>
              </div>
              <div class="mat-card-actions m-0">
                <div class="d-flex justify-content-between">
                  <button *ngIf="canStepBack()"
                          mat-stroked-button
                          color="primary"
                          (click)="gotoPrevSection()">
                    Previous Section
                  </button>
                  <button mat-raised-button
                          color="primary"
                          class="ml-auto"
                          (click)="gotoNextSection()">Next Section
                  </button>
                </div>
              </div>
            </div>


            <div
              *ngIf="!readOnly && !isPreviewMode() && surveyResponse && surveyResponse.id && _surveyDefinition && _surveyDefinition.definition"
              [hidden]="activeSection"
              class="mat-card survey-section"
            >
              <div class="mat-card-title bg-white sticky-top">Review & Submit</div>
              <div class="mat-card-content">
                <app-message messageType="alert"
                             *ngFor="let surveySection of _surveyDefinition.definition;index as i"
                             [icon]="false"
                             [color]="!isSectionComplete(i) ? 'warn' : 'accent'"
                >
                  <div class="d-flex">
                    <mat-icon class="mr-1">{{isSectionComplete(i) ? 'check_circle_outline' : 'priority_high'}}</mat-icon>
                    <div>
                      <h5>
                        {{(surveySection.definition && surveySection.definition.name) || "Unnamed section"}}
                      </h5>
                      <p class="mb-0">

                        <ng-container
                          *ngIf="isSectionComplete(i); then sectionComplete; else sectionNotComplete">
                        </ng-container>

                        <ng-template #sectionComplete>
                          All required questions in section are completed.
                        </ng-template>
                        <ng-template #sectionNotComplete>
                          You need to complete this section before you can submit the survey.
                        </ng-template>

                        <a class="link link--dotted" (click)="activateSection(surveySection)">Review Section</a>
                      </p>


                    </div>
                  </div>
                </app-message>
              </div>
              <div class="mat-card-actions m-0">
                <button mat-raised-button color="accent" (click)="submit(submitButton)" class="w-100"
                        [disabled]="!canSubmit()" #submitButton>Submit
                </button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>


</div>


