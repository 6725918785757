import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {SurveyService} from "../../../../core/survey.service";
import {AuthApiService} from "../../../../core/auth-api.service";
import {catchError, finalize, map} from "rxjs/operators";
import {Observable, of} from "rxjs";
import {MessageComponent} from "../../../../component/message";
import {Survey, SurveyResults, SurveyResultsSection} from "../../../../model/common";
import {UserSettingsService} from "../../../../core/user.settings.service";

@Component({
  selector: 'app-survey-results',
  templateUrl: './survey-results.component.html',
  styleUrls: ['./survey-results.component.scss']
})
export class SurveyResultsComponent implements OnInit {

  @ViewChild('message') message: MessageComponent;

  EDPath = this.auth.getEDPath();

  public includeNotSubmittedResponses: boolean = true;
  public showResultsBelowSafeHarbor: boolean = false;

  public showToggles: boolean = false;
  isLoading = false;

  surveyId = null;
  survey: Survey;
  surveyResults: SurveyResults;
  filters: string[];
  filterState: any = null;
  results;
  isModal = false;

  compensationSectionResults: SurveyResultsSection;

  @Output() onClose = new EventEmitter();

  activeSection: SurveyResultsSection;

  constructor(
    private route: ActivatedRoute,
    public surveyService: SurveyService,
    private auth: AuthApiService,
    private userSettingsService: UserSettingsService,
  ) { }

  ngOnInit() {
    this.showToggles = !this.auth.isUser();
    this.showResultsBelowSafeHarbor = !this.auth.isUser();

    this.surveyId = this.route.snapshot.paramMap.get('survey_id');
    this.clearFilterState();
    this.init(this.surveyId);
  }

  clearFilterState() {
    this.userSettingsService.clearUserSetting('filters');
  }

  public init(surveyId) {
    this.surveyId = surveyId;
    this.message.renderApiError();
    this.isLoading = true;

    const payload = {
      filters: this.filterState,
      includeNotSubmittedResponses: this.includeNotSubmittedResponses,
    };

    this.surveyService.getSurveyResults(surveyId, payload).pipe(finalize(() => {
      this.isLoading = false;
    })).pipe(map(
      r => {
        this.survey = <Survey>r['survey'];
        this.surveyResults = <SurveyResults>r['results'];
        this.filters = <Array<string>>r['filters'];
        this.activeSection = this.surveyResults.sections[0];

        this.compensationSectionResults = this.surveyResults.sections.filter((section) => section['is_compensation_survey']).pop();
      }), catchError((err, caught: Observable<any>) => {
      this.message.renderApiError(err);
      return of([]);
    })).subscribe();
  }

  close() {
    this.onClose.emit();
  }

  onFilter(filterState) {
    this.filterState = filterState;
    this.init(this.surveyId);
  }

  onSurveyPrintClicked() {
    this.userSettingsService.setUserSetting('filters', 'Array', this.filterState);
  }

  onSubmittedResponsesToggle() {
    this.init(this.surveyId);
  }

}
