/**
 * @license
 * Copyright Stbui All Rights Reserved.
 * https://github.com/stbui
 */

import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostBinding, Input, OnInit} from '@angular/core';



@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class MessageComponent implements OnInit {
  private _opened = true;

  @Input() label: string;
  @Input() sublabel: string;
  @Input() class: string;
  @Input() messageType: 'appMessage' | 'alert';
  @Input() color: 'primary' | 'accent' | 'warn' | 'info' | 'secondary' | 'success' | 'warning' | 'danger';
  @Input('icon') icon: any = 'info_outline';

  @HostBinding('class') get hostClasses() {
    const classList: Array<string> = [];
    const isAlert = this.messageType === 'alert';
    if (this.class) {
      classList.push(this.class);
    }
    if (isAlert) {
      classList.push('alert alert-dismissible');
    } else {
      classList.push('app-message');
    }

    if(!this._opened) {
      classList.push('d-none');
    }

    switch (this.color) {
      case 'primary' : {
        isAlert ? classList.push('alert-primary') : classList.push('mat-primary');
        break;
      }
      case 'accent': {
        isAlert ? classList.push('alert-success') : classList.push('mat-accent');
        break;
      }
      case 'warn': {
        isAlert ? classList.push('alert-warning') : classList.push('mat-warn');
        break;
      }
      case 'warning': {
        classList.push('alert-warning');
        break;
      }
      case 'success': {
         classList.push('alert-success');
         break;
      }
      case 'danger': {
         classList.push('alert-danger');
         break;
      }
      case 'info': {
        classList.push('alert-info');
        break;
      }
      default: {
        isAlert ? classList.push('alert-info') : classList.push('mat-primary');
      }
    }
    return classList.join(' ');
  }

  @Input('opened')
  set opened(opened: boolean) {
    if (opened) {
      this.open();
    } else {
      this.close();
    }
  }

  get opened(): boolean {
    return this._opened;
  }

  constructor(private changeDetectorRef: ChangeDetectorRef, private elRef: ElementRef) {
  }

  ngOnInit() {
  }

  open(): void {
    if (!this._opened) {
      this._opened = true;
      this.changeDetectorRef.markForCheck();
      if (this.elRef) {
        setTimeout(()=>{
          this.elRef.nativeElement.scrollIntoView();
        });
      }
    }
  }

  close(): void {
    if (this._opened) {
      this._opened = false;
      this.changeDetectorRef.markForCheck();
    }
  }

  toggle(): void {
    if (this._opened) {
      this.close();
    } else {
      this.open();
    }
  }

  public renderSuccess(r?: {} | null) {
    if (!r) {
      r = {message: null};
    }

    this.color = 'primary';
    this.label = r['message'] || 'Success';
    this.sublabel = null;
    this.open();
  }

  public renderApiError(e?: {} | null) {
    if (!e) {
      this.opened = false;
      return;
    }
    this.color = 'warn';

    if (e['error'] && e['error']['message']) {
      this.label = e['error']['message'];
    } else {
      this.label = e['statusText'];
    }

    let fe = [];
    if (e['error'] && e['error']['errors']) {
      for (let [field, fieldErrors] of Object.entries(e['error']['errors'])) {
        fe.push((fieldErrors as Array<any>).join('<br/>'));
      }
    }

    this.sublabel = fe.join('<br/>');
    this.opened = true;
  }


}
