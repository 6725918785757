import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthApiService} from '../../core/auth-api.service';
import {MessageComponent} from "../../component/message";
import {environment} from "../../../environments/environment";
import {PublicService} from "../../core/public.service";
import {ED} from "../../model/common";

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {

  @ViewChild('message') messageBox: MessageComponent;


  userForm: FormGroup;
  formErrors = {
    email: '',
    password: ''
  };
  validationError = {
    status: '',
    message: ''
  };
  successMsg = {
    status: '',
    message: ''
  };

  validationMessages = {
    email: {
      required: 'Please enter your email address',
      email: 'Please enter your vaild email\n'
    },
    password: {
      required: 'Please enter your password',
      pattern: 'Password must contain numbers and letters',
      minlength: 'Please enter more than 6 characters',
      maxlength: 'Please enter less than 32 characters'
    }
  };

  forgotPassword: boolean = false;

  passwordResetEmail: string = '';

  edLogo = 'assets/images/sensiblesurveyslogo.png';
  edInfo: ED;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private auth: AuthApiService,
    private route: ActivatedRoute,
    private publicService: PublicService
  ) {
  }

  ngOnInit() {

    this.route.params.subscribe(params => {
      const edPath = params['ed-path'];

      if (!edPath) {
        return;
      }
      let originalLogo = this.edLogo;
      this.publicService.publicEDInfo(edPath).subscribe(r => {
        this.edLogo = r['logo_url'] || originalLogo;
        this.edInfo = r;
      }, e => {
        this.edLogo = originalLogo;
      });

    });

    if (this.auth.user) {
      this.auth.logout().subscribe();
    }
    this.buildForm();
  }

  buildForm() {
    this.userForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: [
        '',
        [
          // Validators.pattern('^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$'),
          // Validators.minLength(6),
          // Validators.maxLength(32)
        ]
      ]
    });

    this.userForm.valueChanges.subscribe(data => this.onValueChanged(data));
    this.onValueChanged();
  }

  onValueChanged(data?: any) {
    if (!this.userForm) {
      return;
    }
    const form = this.userForm;
    for (const field in this.formErrors) {
      if (Object.prototype.hasOwnProperty.call(this.formErrors, field)) {
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (Object.prototype.hasOwnProperty.call(control.errors, key)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }
  }

  signInWithEmail() {
    this.messageBox.renderApiError();
    this.auth.login(this.userForm.value['email'], this.userForm.value['password']).subscribe((response) => {
      this.messageBox.renderSuccess(response);
      this.afterSignIn();
    }, (err) => {
      this.messageBox.renderApiError(err);
    });
  }

  login() {
    this.signInWithEmail();
  }

  private afterSignIn() {
    this.router.navigate(['/']);
  }

  activatePasswordReset() {
    this.forgotPassword = true;
    this.validationError.status = '';
    this.validationError.message = '';
  }

  sendPasswordResetEmail() {
    this.messageBox.renderApiError();
    this.auth.sendPasswordResetEmail(this.passwordResetEmail)
      .subscribe((response) => {
        this.messageBox.renderSuccess(response);
        this.successMsg.status = "Email Sent";
        this.successMsg.message = "An email with password reset link has been sent, please check your inbox";
      }, (err) => {
        this.messageBox.renderApiError(err);
      });
  }

  version(){
    return environment.version;
  }
}
