import {Component, Input, OnInit} from '@angular/core';
import {Survey, SurveyResultsSection} from "../../../../../model/common";

@Component({
  selector: 'app-survey-print-results-compensation',
  templateUrl: './survey-print-results-compensation.component.html',
  styleUrls: ['./survey-print-results-compensation.component.scss']
})
export class SurveyPrintResultsCompensationComponent implements OnInit {

  @Input('survey') survey: Survey;
  @Input('results') results: SurveyResultsSection;
  @Input('sectionNumber') sectionNumber: number;
  @Input('showResultsBelowSafeHarbor') showResultsBelowSafeHarbor: boolean;

  constructor(
  ) { }

  ngOnInit() {
  }

  getKeys(object) {
    return Object.keys(object);
  }

  sanitizeHref(key: string) {
    return key.replace(/[ /()-]/g, '_');
  }

}
