<ng-container *ngIf="sectionResult">
  <h1 class="ml-3">
    {{sectionNumber+1}}. {{sectionResult.name}}
  </h1>
  <ng-container *ngFor="let question of sectionResult.questions;index as i">
    <app-survey-results-question
      [survey]="survey"
      [question]="question"
      [sectionNumber]="sectionNumber+1"
      [questionNumber]="i+1"
      [showResultsBelowSafeHarbor]="showResultsBelowSafeHarbor"
    >
    </app-survey-results-question>
  </ng-container>

</ng-container>
