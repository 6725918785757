<div class="dashboard-header">
    <div class="d-flex">
        <div class="flex-grow-1">
            <h1 class="dashboard-header__title">Start New Survey</h1>
            <p class="dashboard-header__desc">Complete all survey creation steps to start new survey.</p>
        </div>
    </div>
</div>

<app-message
    #message
    [opened]="false"
>
    <button app-message-actions mat-icon-button (click)="message.close()">
        <mat-icon>cancel</mat-icon>
    </button>
</app-message>

<mat-card class="material mat-typography">

    <mat-card-content>

        <mat-horizontal-stepper #stepper labelPosition="bottom">
            <mat-step #step1
                      [label]="'Select Survey Template'"
                      [completed]="!!surveyTemplate">
                <app-step-select-survey-template 
                    [message]="message"
                    [selectedTemplate]="surveyTemplate"
                    [selectedTemplateID]="duplicateSurveyID"
                    (onTemplateSelected)="onTemplateSelected($event)">
                </app-step-select-survey-template>
                <div class="d-flex mt-4">
                    <button class="ml-auto" mat-raised-button color="primary" matStepperNext>Next</button>
                </div>
            </mat-step>

            <mat-step #step2 [label]="'Occupations'" [completed]="occupations && occupations.length">
                <app-step-industry-and-occupation-list
                    [message]="message"
                    [occupations]="occupations"
                    (occupationsChanged)="onOccupationsChanged($event)"
                ></app-step-industry-and-occupation-list>
                <div class="d-flex mt-4">
                    <button mat-raised-button color="primary" matStepperPrevious>Previous</button>
                    <button class="ml-auto" mat-raised-button color="primary" matStepperNext>Next</button>
                </div>
            </mat-step>

            <mat-step #step3 [label]="'Preview Survey Questions'" [completed]="questionsPreview.questionsApproved">
                <app-step-preview-survey-questions
                    #questionsPreview
                    [message]="message"
                    [surveyTemplate]="surveyTemplate"
                    (onSurveyTemplateEdited)="onSurveyTemplateEdited($event)"
                ></app-step-preview-survey-questions>
                <div class="d-flex mt-4">
                    <button mat-raised-button color="primary" matStepperPrevious>Previous</button>
                    <button class="ml-auto" mat-raised-button color="primary" matStepperNext>Next</button>
                </div>
            </mat-step>

            <mat-step #step4 [label]="'Survey Information'"
                      [completed]="survey && survey.title && survey.start_date && survey.end_date && survey.description">

                <app-step-survey-details-form
                    #surveyDetails
                    [(survey)]="survey"
                ></app-step-survey-details-form>

                <div class="d-flex mt-4">
                    <button mat-raised-button color="primary" matStepperPrevious>Previous</button>
                    <button class="ml-auto" mat-raised-button color="primary" matStepperNext>Next</button>
                </div>
            </mat-step>

            <mat-step #step5 [label]="'Invite Users'" [completed]="users.length > 0">
                <app-step-invite-users [message]="message"
                                       [selectAll]="true"
                                       (onUsersSelected)="onUsersSelected($event)">
                </app-step-invite-users>
                <div class="d-flex mt-4">
                    <button mat-raised-button color="primary" matStepperPrevious>Previous</button>
                    <button class="ml-auto" mat-raised-button color="primary" matStepperNext>Next</button>
                </div>
            </mat-step>

            <mat-step #step6 [label]="'Preview'" [completed]="step1.completed && step2.completed && step3.completed && step4.completed && step5.completed">
                <div class="survey-preview mat-typography">
                    <mat-card class="border" [class.border-danger]="!surveyTemplate">
                        <mat-card-title>
                            <h3>1. {{step1.label}}</h3>
                            <button mat-button color="accent" (click)="stepper.selected=step1">
                                <mat-icon>edit</mat-icon>
                                Edit
                            </button>
                        </mat-card-title>
                        <mat-card-content>
                            <app-message *ngIf="surveyTemplate" messageType="alert" color="success">
                                <strong *ngIf="surveyTemplate.edited">Customized</strong> {{surveyTemplate.survey ? surveyTemplate.survey.title : surveyTemplate.title}}
                            </app-message>

                            <app-message messageType="alert" color="danger"  *ngIf="!surveyTemplate">
                                Survey template is not selected.
                            </app-message>
                        </mat-card-content>
                    </mat-card>
                    <mat-card>
                        <mat-card-title>
                            <h3>2. {{step2.label}}</h3>
                            <button mat-button color="accent" (click)="stepper.selected=step2">
                                <mat-icon>edit</mat-icon>
                                Edit
                            </button>
                        </mat-card-title>
                        <mat-card-content>
                            <div *ngIf="occupations">
                                <app-message messageType="alert" color="success" [icon]="false">
                                    <mat-icon class="align-middle mr-4">business</mat-icon> Total Industries involved: {{getIndustriesCount(occupations)}}
                                </app-message>
                                <app-message messageType="alert" color="success" [icon]="false">
                                    <mat-icon class="align-middle mr-4">business_center</mat-icon> Occupations involved: {{occupations.length}}
                                </app-message>
                            </div>
                        </mat-card-content>
                        <mat-card-actions>

                        </mat-card-actions>
                    </mat-card>
                    <mat-card class="border" [class.border-danger]="!questionsPreview.questionsApproved">
                        <mat-card-title>
                            <h3>3. {{step3.label}}</h3>
                            <button mat-button class="mat-primary" color="accent" (click)="stepper.selected=step3">
                                <mat-icon>edit</mat-icon>
                                Edit
                            </button>
                        </mat-card-title>
                        <mat-card-content>
                            <app-message messageType="alert" color="danger" *ngIf="!questionsPreview.questionsApproved">
                                Survey questions has not been approved!
                            </app-message>
                            <app-message messageType="alert" color="success" *ngIf="questionsPreview.questionsApproved" icon="check">
                                You have reviewed and approved the existing survey questions.
                            </app-message>

                            <p>
                                To view or edit survey again, click Edit Button on this right side of the box or
                                <a (click)="stepper.selected=step3;" class="link link--dotted">click here</a>.
                            </p>
                        </mat-card-content>
                    </mat-card>
                    <mat-card class="border" [class.border-danger]="!isSurveyInfoFilled()">
                        <mat-card-title>
                            <h3>4. {{step4.label}}</h3>
                            <button mat-button color="accent" (click)="stepper.selected=step4">
                                <mat-icon>edit</mat-icon>
                                Edit
                            </button>
                        </mat-card-title>
                        <mat-card-content>
                            <app-message messageType="alert"
                                         color="success"
                                         *ngIf="isSurveyInfoFilled()"
                                         [icon]="false">
                                <div class="d-flex align-items-start">
                                    <mat-icon class="align-middle mr-4">insert_invitation</mat-icon>
                                    <div class="ml-2">
                                        <p><strong>Survey title:</strong> {{survey.title}}</p>
                                        <p><strong>Open date:</strong> {{survey.start_date | formatDate | date}}</p>
                                        <p><strong>Close date:</strong> {{survey.end_date | formatDate | date}}</p>
                                    </div>
                                </div>
                            </app-message>

                            <app-message messageType="alert" color="danger" *ngIf="!isSurveyInfoFilled()">
                                Survey information is not filled or incomplete.
                            </app-message>
                        </mat-card-content>
                    </mat-card>
                    <mat-card class="border" [class.border-danger]="!users.length">
                        <mat-card-title>
                            <h3>5. {{step5.label}}</h3>
                            <button mat-button color="accent" (click)="stepper.selected=step5">
                                <mat-icon>edit</mat-icon>
                                Edit
                            </button>
                        </mat-card-title>
                        <mat-card-content>

                            <div class="wrapper-business-users-table">
                                <table #businessUsersTable class="business-users-table w-100" mat-table
                                       [dataSource]="usersByBusiness">

                                    <ng-container matColumnDef="business">
                                        <th mat-header-cell *matHeaderCellDef>Business Name</th>
                                        <td mat-cell *matCellDef="let element"> {{element['business']}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="count">
                                        <th mat-header-cell *matHeaderCellDef>Selected Survey Takers</th>
                                        <td mat-cell *matCellDef="let element"> {{element['count']}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="total">
                                        <td mat-footer-cell *matFooterCellDef colspan="2" class="text-left">
                                            <span *ngIf="users.length">Total: {{users.length}}</span>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row
                                        *matHeaderRowDef="usersBusinessTableColumns; sticky: true"></tr>
                                    <tr mat-row *matRowDef="let row; columns: usersBusinessTableColumns"></tr>
                                    <tr mat-footer-row *matFooterRowDef="['total']; sticky: true"></tr>

                                </table>
                                <app-message *ngIf="!users.length" messageType="alert" color="danger">
                                    No survey takers selected
                                </app-message>
                            </div>
                        </mat-card-content>
                    </mat-card>

                    <mat-card>
                        <mat-card-content>
                            <app-message messageType="alert" color="primary" [icon]="false">
                                <p><strong>Initiate Survey</strong></p>
                                <ol>
                                    <li>Open this survey to be filled out as of the open date
                                        of: <strong class="text-success">{{survey.start_date ? (survey.start_date | formatDate | date) : 'N/A' }}</strong></li>
                                    <li>Email survey takers and inform them they have been invited to the survey.</li>
                                    <li>Inform users of the open and close date of the survey.</li>
                                    <li>Automatically remind users that have not started the survey after 15 days and 30 days
                                        that the
                                        survey is waiting to be filled.
                                    </li>
                                    <li>Close the survey on the close date of: <strong class="text-success">{{survey.end_date ? (survey.end_date | formatDate | date) : 'N/A' }}</strong>.</li>
                                </ol>

                                <p>
                                    During the time the survey is open, or after it has closed, you may restart the survey,
                                    extend the time, and add, edit, or remove users. But you may not edit the questions in the survey after it has
                                    been initiated. In order to do this, you must close the survey and restart it.
                                </p>
                            </app-message>
                        </mat-card-content>
                    </mat-card>
                </div>
            </mat-step>

        </mat-horizontal-stepper>

        <div *ngIf="stepper.selected === step6" class="mt-4">
            <button class="mat-primary"
                    color="accent"
                    class="w-100"
                    mat-raised-button
                    (click)="submit()"
                    [disabled]="!step6.completed"
            >Initiate Survey
            </button>
        </div>

    </mat-card-content>
</mat-card>
