<h2 mat-dialog-title>{{!isEditingExistingSurvey() ? 'Create Survey Template' : 'Edit survey'}}
  <button mat-icon-button type="button" color="primary" (click)="onCloseClicked()">
    <mat-icon>close</mat-icon>
  </button>
</h2>
<app-message
  #message
  [opened]="false"
>
  <button app-message-actions mat-icon-button (click)="message.close()">
    <mat-icon>cancel</mat-icon>
  </button>
</app-message>
<mat-dialog-content>
  <mat-form-field class="w-100 mb-4" *ngIf="!isEditingExistingSurvey()">
    <input matInput [(ngModel)]="surveyDefinition.title" placeholder="Survey Template Title"/>
    <mat-hint align="start"><strong>Provide survey template title.</strong></mat-hint>
  </mat-form-field>
  <mat-form-field class="w-100">
    <mat-label>Quick Filter questions</mat-label>
    <mat-select [formControl]="formFilterFieldsControl" multiple (selectionChange)="filterSelectionChange($event)">
      <mat-optgroup *ngFor="let group of questionGroups" [label]="group.name">
        <mat-option *ngFor="let question of group.questions" [value]="question.value">
          {{question.viewValue}}
        </mat-option>
      </mat-optgroup>
    </mat-select>
    <mat-hint align="start"><strong>Select questions used for quick filtering in the results presentation.</strong>
    </mat-hint>
  </mat-form-field>

  <mat-form-field class="w-100 mt-1">
    <mat-label>Excluded questions from survey results</mat-label>
    <mat-select [formControl]="excludedQuestionsFieldsControl" multiple (selectionChange)="excludedQuestionsSelectionChange($event)">
      <mat-optgroup *ngFor="let group of excludedQuestionsGroups" [label]="group.name">
        <mat-option *ngFor="let question of group.questions" [value]="question.value">
          {{question.viewValue}}
        </mat-option>
      </mat-optgroup>
    </mat-select>
    <mat-hint align="start"><strong>Select questions which should not be included in the survey results.</strong>
    </mat-hint>
  </mat-form-field>

  <div class="sections-list" #sectionsList>
    <mat-list class="sections-list">
      <h3 mat-subheader>Survey Sections</h3>
      <div>
        <div
          *ngIf="!surveyDefinition || !surveyDefinition.definition || !isArray(surveyDefinition.definition) || !surveyDefinition.definition.length"
          class="mat-typography text-center">
          <h2>No survey sections defined</h2>
          <h3>Start by adding your first survey section!</h3>
          <button mat-stroked-button (click)="addSurveySection()">
            <mat-icon>add</mat-icon>
            Add Survey Section
          </button>
        </div>

        <mat-list-item *ngFor="let section of surveyDefinition.definition; index as i"
                       (click)="editSurveyDefinition($event, section, i)" matRipple>

          <mat-icon mat-list-icon *ngIf="!section.is_compensation_survey">poll</mat-icon>
          <mat-icon mat-list-icon *ngIf="section.is_compensation_survey" class="text-primary">attach_money</mat-icon>

          <h4 mat-line *ngIf="section.definition && section.definition.name">{{section.definition.name}}</h4>
          <h4 mat-line *ngIf="!section.definition" class="unnamed-section-title">--- Unnamed survey section ---</h4>
          <p mat-line>
            {{getQuestionsCount(section.definition || null)}} Questions
            <span *ngIf="section.is_compensation_survey" class="badge badge-primary">Compensation Survey</span>
          </p>

          <button mat-icon-button [disabled]="i===0" (click)="moveSection($event, i,i-1)">
            <mat-icon>arrow_drop_up</mat-icon>
          </button>
          <button mat-icon-button [disabled]="!surveyDefinition.definition[i+1]" (click)="moveSection($event, i,i+1)">
            <mat-icon>arrow_drop_down</mat-icon>
          </button>

          <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="editSurveyDefinition($event, section, i)">
              <mat-icon>edit</mat-icon>
              <span>Edit Survey Section</span>
            </button>
            <button mat-menu-item (click)="deleteSurveySection($event, i)">
              <mat-icon>delete</mat-icon>
              <span>Delete Survey Section</span>
            </button>
            <button
              *ngIf="!section.is_compensation_survey"
              mat-menu-item
              (click)="markAsCompensationSurvey($event, section, i)">
              <mat-icon>attach_money
              </mat-icon>
              <span>Mark as Compensation survey</span>
            </button>
            <button *ngIf="section.is_compensation_survey"
                    mat-menu-item
                    (click)="unmarkCompensationSurvey($event, section, i)">
              <mat-icon>poll
              </mat-icon>
              <span>Unmark as Compensation survey</span>
            </button>
          </mat-menu>
        </mat-list-item>
      </div>
    </mat-list>
  </div>
  <button mat-stroked-button (click)="addSurveySection()"><i class="material-icons">add</i> Add Survey Section <span
    class="badge badge-info">{{surveyDefinition.definition.length}}</span>
  </button>
  <p class="text-muted help-text">Click Sections List to Edit. Use
    <mat-icon>more_vert</mat-icon>
    to access menu to edit/delete section or update as
    compensation section. Use
    <mat-icon>arrow_drop_up</mat-icon>
    <mat-icon>arrow_drop_down</mat-icon>
    to sort sections.
  </p>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-raised-button type="button" color="primary" (click)="previewSurvey()" class="preview-button">
    <mat-icon>visibility</mat-icon>
    Preview Survey
  </button>
  <button mat-raised-button [color]="'accent'" (click)="onSaveClicked()">
    <mat-icon>save</mat-icon>
    Save Changes
  </button>
</mat-dialog-actions>
